import React from "react";
import { Typography, Box } from "@mui/material";

const AddressBook = () => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Address Book
      </Typography>
      <Typography variant="body1">Here are your addresses...</Typography>
    </Box>
  );
};

export default AddressBook;
