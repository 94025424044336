import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import Checkbox from "@mui/material/Checkbox";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import InputAdornment from "@mui/material/InputAdornment";
// import FormHelperText from "@mui/material/FormHelperText";
// import FormControl from "@mui/material/FormControl";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
// import { setBackMenu } from "./redux-slices/BackMenuSlice";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";

// import ToggleButton from "@mui/material/ToggleButton";
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Slider from "@mui/material/Slider";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
// import Typography from "@mui/material/Typography";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import Divider from "@mui/material/Divider";
import { unsetGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";
import { setImageURL } from "./redux-slices/ImageSlice";
import { rotateImage } from "./image-processing-tools";

const ImageRotatePanel = (props) => {
  const dispatch = useDispatch();
  // const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [rotation, setRotation] = useState("");
  const [flip, setFlip] = useState("");
  const [sliderAngle, setSliderAngle] = useState(0);
  const [state, setState] = useState(false);
  useEffect(() => {
    if (document.getElementById("canvas")) {
      rotateImage(props.imageURL, rotation, flip, sliderAngle)
        .then((url) => {
          dispatch(setImageURL(url));
        })
        .catch((error) => {
          console.log("Error processing the image: " + error);
        });
      setRotation("");
      setFlip("");
    }
  }, [state]);
  return (
    <Box>
      <Button
        onClick={() => {
          //   dispatch(setBackMenu());
          dispatch(unsetGridMenuClicked());
        }}
      >
        <ArrowBackIcon></ArrowBackIcon>
      </Button>
      <Box style={{ padding: "5%" }}>
        <Stack direction="column" spacing={2}>
          <Divider>Rotate</Divider>

          <Stack direction={"row"}>
            <Button
              size="small"
              onClick={() => {
                setRotation("left");
                setState(!state);
              }}
            >
              <RotateLeftIcon fontSize="small" />
            </Button>
            <Button
              size="small"
              onClick={() => {
                setRotation("right");
                setState(!state);
              }}
            >
              <RotateRightIcon fontSize="small" />
            </Button>
          </Stack>

          {/* <Divider light /> */}
          <Divider>Flip</Divider>

          <Stack direction={"row"}>
            <Button
              size="small"
              onClick={() => {
                setFlip("horizontal");
                setState(!state);
              }}
            >
              <SwapHorizontalCircleIcon fontSize="small" />
            </Button>
            <Button
              size="small"
              onClick={() => {
                setFlip("vertical");
                setState(!state);
              }}
            >
              <SwapVerticalCircleIcon fontSize="small" />
            </Button>
          </Stack>

          <Box sx={{ m: 1, paddingTop: "20%" }}>
            <Slider
              disabled={false}
              marks={false}
              max={90}
              min={-90}
              //   size="medium"
              valueLabelDisplay="on"
              defaultValue={0}
              onChange={(e) => {
                setSliderAngle(e.target.value);
                setRotation("slider");
                setFlip("");
              }}
            />
          </Box>
          <Divider light />
          <Button
            onClick={() => {
              rotateImage(props.imageURL, rotation, flip, sliderAngle)
                .then((url) => {
                  dispatch(setImageURL(url));
                })
                .catch((error) => {
                  console.log("Error processing the image: " + error);
                });
            }}
            variant="contained"
          >
            Apply
          </Button>
          <Button
            onClick={() => {
              dispatch(unsetGridMenuClicked());
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
export default ImageRotatePanel;
