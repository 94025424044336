import React from "react";
import Header from "./Header";
import { Box, Container, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const RefundPolicy = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>Refund Policy | Okay Artist</title>
        <meta
          name="description"
          content="Read our refund policy to understand our process for returns, exchanges, and refunds at Okay Artist."
        />
        <meta
          name="keywords"
          content="refund policy, returns, exchanges, refund, okay artist"
        />
        <meta property="og:title" content="Refund Policy | Okay Artist" />
        <meta
          property="og:description"
          content="Learn about Okay Artist's refund policy, including how we handle returns, refunds, and non-returnable items."
        />
        <meta
          property="og:url"
          content="https://www.okayartist.com/refund-policy"
        />
        <meta
          property="og:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Refund Policy | Okay Artist" />
        <meta
          name="twitter:description"
          content="Understand our refund policy and how we manage returns and refunds at Okay Artist."
        />
        <meta
          name="twitter:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
      </Helmet>

      <Header />
      <Container
        sx={{
          // backgroundColor: "gray",

          mt: "5%",
          mb: "5%",
        }}
      >
        <Stack
          direction={"column"}
          spacing={8}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Refund policy</Typography>
          <Divider orientation="horizontal" flexItem />
          <Typography variant="body1" sx={{ mb: "2%" }}>
            You can always contact us with any questions at{" "}
            <a href="mailto:help@okayartist.com">help@okayartist.com</a>.
          </Typography>
          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Damages and issues
            </Typography>
            <Typography variant="body1">
              {`Please inspect your order upon reception and contact us immediately if the item is defective, damaged or if you receive the wrong item, so that we can evaluate the issue and make it right.`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Exceptions / non-returnable items
            </Typography>
            <Typography variant="body1">
              {`Certain types of items cannot be returned, like perishable goods (such as food, flowers, or plants), custom products (such as special orders or personalized items), and personal care goods (such as beauty products). We also do not accept returns for hazardous materials, flammable liquids, or gases. Please get in touch if you have questions or concerns about your specific item. Unfortunately, we cannot accept returns on sale items or gift cards.`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Refunds
            </Typography>
            <Typography variant="body1">
              {`We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method. Please remember it can take some time for your bank or credit card company to process and post the refund too.`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Exchanges
            </Typography>
            <Typography variant="body1">
              {`Unfortunately, we don't accept exchanges as most of the products are personalized, but please contact us if there's something wrong with the product. The nature of personalized products makes it not suitable for exchangeg of the product.`}
            </Typography>
          </Box>
          {/* <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Exchanges
            </Typography>
            <Typography variant="body1">
              {`Unfortunately, we don't accept exchanges as most of the products are personalized, but please contact us if there's something wrong with the product.`}
            </Typography>
          </Box> */}
        </Stack>
      </Container>
      <Footer></Footer>
    </Box>
  );
};

export default RefundPolicy;
