import { createSlice } from "@reduxjs/toolkit";

export const BackMenuSlice = createSlice({
  name: "backMenu",
  initialState: {
    backMenu: false,
  },
  reducers: {
    setBackMenu: (state) => {
      state.backMenu = !state.backMenu;
    },
  },
});

export const { setBackMenu } = BackMenuSlice.actions;

export default BackMenuSlice.reducer;
