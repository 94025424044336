import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import axiosInstance from "../axiosInstance";
// import api from "../axiosConfig";
import api from "../axiousCustom";
import Rating from "@mui/material/Rating";
import { useMediaQuery } from "@mui/material";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [reviewRating, setReviewRating] = useState(0);
  const [reviewComment, setReviewComment] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);

  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleImageUpload = (event) => {
    setUploadedImage(event.target.files[0]);
  };

  const handleSubmitReview = async (event, listing_id, item_id) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("rating", reviewRating);
    formData.append("comment", reviewComment);
    formData.append("listing_id", listing_id);
    formData.append("item_id", item_id);

    if (uploadedImage) {
      formData.append("image", uploadedImage); // Attach image if present
    }

    // // Log form data to check if all fields are present
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ": " + pair[1]);
    // }

    try {
      const response = await api.post("/api/submit-review/", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure correct content type
        },
      });
      alert("Review submitted successfully!");
    } catch (error) {
      // console.error("Error submitting review:", error);
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.get("api/view-orders/");
        setOrders(response.data.orders);
        // console.log(
        //   "BREAKPOINT",
        //   JSON.stringify(response.data.orders, null, 2)
        // );
        // console.log("BREAKPOINT" + response.data.orders.items[0].review);
      } catch (error) {
        // console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    // console.log("API Response:", orders);
  }, [orders]);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Your Orders
      </Typography>
      {orders.length > 0 ? (
        orders.map((order) => (
          <Box
            key={order.order_id}
            mb={4}
            p={2}
            border={1}
            borderColor="grey.400"
            borderRadius={4}
          >
            <Typography variant="h6">Order ID: {order.order_id}</Typography>
            <Typography variant="body1">
              <strong>Name:</strong> {order.name}
            </Typography>
            <Typography variant="body1">
              <strong>Email:</strong> {order.email}
            </Typography>
            <Typography variant="body1">
              <strong>Address:</strong> {order.address}, {order.city},{" "}
              {order.state}, {order.postal_code}, {order.country}
            </Typography>
            <Typography variant="body1">
              <strong>Total Price:</strong> ${order.total_price}
            </Typography>
            <Typography variant="body1">
              <strong>Items Total Price:</strong> ${order.items_total_price}
            </Typography>
            <Typography variant="body1">
              <strong>Shipping Total Price:</strong> $
              {order.shipping_total_price}
            </Typography>
            <Typography variant="body1">
              <strong>Order Date:</strong>{" "}
              {new Date(order.created_at).toLocaleString("en-US", {
                timeZoneName: "short",
              })}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6">Items:</Typography>
            <List>
              {order.items.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText
                    primary={`Item ID: ${item.id}`}
                    secondary={
                      <>
                        <Stack direction={"row"} spacing={3}>
                          <Typography component="span" variant="body2">
                            Quantity: {item.quantity}
                          </Typography>
                          <Typography component="span" variant="body2">
                            Size: {item.size}
                          </Typography>
                          <Typography component="span" variant="body2">
                            Color: {item.color}
                          </Typography>
                        </Stack>
                        <br />
                        <Typography component="span" variant="body2">
                          Total Price: ${item.total_price}
                        </Typography>
                        <br />
                        <Link
                          href={item.mockup_image_url}
                          target="_blank"
                          rel="noopener"
                        >
                          View Mockup
                        </Link>
                      </>
                    }
                  />
                  <img
                    src={item.mockup_image_url}
                    alt="Mockup Preview"
                    style={{
                      display: matchesXS ? "none" : "bloack",
                      width: "10%",
                      height: "auto",
                      // maxHeight: "150px",
                      // objectFit: "cover",
                    }}
                  />
                  {item.review != null ? (
                    <Stack direction={"column"} sx={{ ml: 2 }}>
                      <Rating
                        name="read-only"
                        value={item.review.rating}
                        readOnly
                      />
                      <Typography variant="body">
                        {item.review.comment}
                      </Typography>
                    </Stack>
                  ) : (
                    <>
                      <Box sx={{ ml: 3 }}>
                        <Typography variant="h6">Leave a Review</Typography>
                        <Box
                          component="form"
                          onSubmit={(event) => {
                            handleSubmitReview(event, item.listing_id, item.id);
                          }}
                        >
                          <Rating
                            name="simple-controlled"
                            value={reviewRating}
                            onChange={(event, newValue) => {
                              setReviewRating(newValue);
                            }}
                          />
                          <Stack direction={"column"} spacing={2}>
                            <TextField
                              label="Your Comment"
                              multiline
                              rows={4}
                              variant="outlined"
                              fullWidth
                              value={reviewComment}
                              onChange={(e) => setReviewComment(e.target.value)}
                            />
                            <Button variant="contained" component="label">
                              Upload Image (Optional)
                              <input
                                type="file"
                                hidden
                                onChange={handleImageUpload}
                              />
                            </Button>
                            {uploadedImage && (
                              <Typography variant="body2">
                                {uploadedImage.name}
                              </Typography>
                            )}
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Submit Review
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
        ))
      ) : (
        <Typography>No orders found.</Typography>
      )}
    </Box>
  );
};

export default Orders;
