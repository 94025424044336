import { createSlice } from "@reduxjs/toolkit";

export const imageSlice = createSlice({
  name: "image",
  initialState: {
    imageURL: "",
    originalImageURL: "",
    activeTemplate: false,
    imgTemplateURL: "",
    designFileURL: "",
    imgMockupURL: "",
    productPreviewMockupURL: "",
    customImage1: "",
    customImage2: "",
    customImage3: "",
    customImage4: "",
    customImage5: "",
  },
  reducers: {
    setImageURL: (state, action) => {
      state.imageURL = action.payload;
    },
    setOriginalImageURL: (state, action) => {
      state.originalImageURL = action.payload;
    },
    setActiveTemplate: (state, action) => {
      state.activeTemplate = action.payload;
    },
    setImgTemplateURL: (state, action) => {
      state.imgTemplateURL = action.payload;
    },
    setDesignFileURL: (state, action) => {
      state.designFileURL = action.payload;
    },
    setImgMockupURL: (state, action) => {
      state.imgMockupURL = action.payload;
    },
    setProductPreviewMockupURL: (state, action) => {
      state.productPreviewMockupURL = action.payload;
    },
    setCustomImage1: (state, action) => {
      state.customImage1 = action.payload;
    },
    setCustomImage2: (state, action) => {
      state.customImage1 = action.payload;
    },
    setCustomImage3: (state, action) => {
      state.customImage1 = action.payload;
    },
    setCustomImage4: (state, action) => {
      state.customImage1 = action.payload;
    },
    setCustomImage5: (state, action) => {
      state.customImage1 = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setImageURL,
  setOriginalImageURL,
  setActiveTemplate,
  setImgTemplateURL,
  setDesignFileURL,
  setImgMockupURL,
  setProductPreviewMockupURL,
  setCustomImage1,
  setCustomImage2,
  setCustomImage3,
  setCustomImage4,
  setCustomImage5,
} = imageSlice.actions;

export default imageSlice.reducer;
