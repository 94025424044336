import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// import { Link } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import theme from "../theme";
import { ThemeProvider, styled } from "@mui/material/styles";

const StyledLink = styled(RouterLink)({
  textDecoration: "none",
  color: "inherit",
  display: "flex",
  width: "100%",
});

export default function MobileMenu(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuPage, setMenuPage] = useState("");

  //   const toggleDrawer = (open) => (event) => {
  //     if (
  //       event.type === "keydown" &&
  //       (event.key === "Tab" || event.key === "Shift")
  //     ) {
  //       return;
  //     }
  //     setMenuOpen(open);
  //   };

  const menuItems = ["Home", "Design", "Catalog", "About", "Contact"];
  const handleMenuItem = (menu) => {
    if (menu === "Design") {
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar> */}
      <Drawer
        anchor={"left"}
        open={props.menuOpen}
        onClose={props.toggleDrawer(false)}
      >
        <List>
          {menuItems.map((text, index) => (
            <ListItem button key={text} onClick={props.toggleDrawer(false)}>
              <StyledLink to={`/${text}`}>
                <ListItemText primary={text} />
              </StyledLink>
              {menuPage}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </ThemeProvider>
  );
}
