import React from "react";
import Header from "./Header";
import { Box, Container, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Footer from "./Footer";
import { motion, AnimatePresence } from "framer-motion";
import { pageVariants, pageTransition } from "./Animations";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>About Us | Okay Artist</title>
        <meta
          name="description"
          content="Learn more about Okay Artist and our mission to provide unique custom designs."
        />
        <meta
          name="keywords"
          content="about okay artist, custom designs, mission"
        />
        <meta property="og:title" content="About Us | Okay Artist" />
        <meta
          property="og:description"
          content="Learn more about Okay Artist and our mission to provide unique custom designs."
        />
        <meta
          property="og:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
        <meta property="og:url" content="https://www.okayartist.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us | Okay Artist" />
        <meta
          name="twitter:description"
          content="Learn more about Okay Artist and our mission to provide unique custom designs."
        />
        <meta
          name="twitter:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
      </Helmet>
      <Header />
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Container
          sx={{
            // backgroundColor: "gray",

            mt: "5%",
            mb: "5%",
          }}
        >
          <Stack
            direction={"column"}
            spacing={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h2">About Us</Typography>
            <Divider orientation="horizontal" flexItem />
            <Box>
              <Typography variant="body1" sx={{ mb: "2%" }}>
                Welcome to Okay Artist!
              </Typography>
              <Typography variant="body1">
                {`We aim to offer our customers a variety of
            the latest clothing designs. We’ve come a long way, so we know
            exactly which direction to take when supplying you with high quality
            yet budget-friendly products. We offer all of this while providing
            excellent customer service and friendly support. We always keep an
            eye on the latest trends and put our customers’ wishes first. That
            is why we have satisfied customers all over the world, and are
            thrilled to be a part of the clothing industry. The interests of our
            customers are always top priority for us, so we hope you will enjoy
            our products as much as we enjoy making them available to you. A
            brand of Max Intel, Inc, registered in the US.`}
              </Typography>
            </Box>
          </Stack>
        </Container>
      </motion.div>
      <Footer></Footer>
    </Box>
  );
};

export default AboutPage;
