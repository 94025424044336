import { createSlice } from "@reduxjs/toolkit";

export const canvasMenuSlice = createSlice({
  name: "selectedMenu",
  initialState: {
    value: "image",
  },
  reducers: {
    updateCanvasMenu: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateCanvasMenu } = canvasMenuSlice.actions;

export default canvasMenuSlice.reducer;
