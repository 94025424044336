import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
  startTransition,
} from "react";
import { Box, Grow } from "@mui/material";
import { Helmet } from "react-helmet";
import { SlideshowSkeleton } from "./Skeletons";

const Header = lazy(() => import("./Header"));
const Footer = lazy(() => import("./Footer"));
const ImageSlideShowComponent = lazy(() => import("./ImageSlideShowComponent"));
const CollectionList = lazy(() => import("./CollectionList"));
const FeaturedProductList = lazy(() => import("./FeaturedProductList"));
const FeaturedProductComponent = lazy(() =>
  import("./FeaturedProductComponent")
);
const ImageComparison = lazy(() => import("./ImageComparison"));

const HomePage = () => {
  const [showSlideshow, setShowSlideshow] = useState(false);
  const [showCollectionList, setShowCollectionList] = useState(false);
  const [showFeaturedProducts, setShowFeaturedProducts] = useState(false);
  const [showFeaturedProduct, setShowFeaturedProduct] = useState(false);
  const [showImageComparison, setShowImageComparison] = useState(false);

  // Show Slideshow first
  useEffect(() => {
    setShowSlideshow(true);
  }, []);

  useEffect(() => {
    if (showSlideshow) {
      const timer = setTimeout(() => {
        startTransition(() => setShowCollectionList(true));
      }, 2500);
      return () => clearTimeout(timer);
    }
  }, [showSlideshow]);

  useEffect(() => {
    if (showCollectionList) {
      const timer = setTimeout(() => {
        startTransition(() => setShowFeaturedProducts(true));
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [showCollectionList]);

  useEffect(() => {
    if (showFeaturedProducts) {
      const timer = setTimeout(() => {
        startTransition(() => setShowFeaturedProduct(true));
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [showFeaturedProducts]);

  useEffect(() => {
    if (showFeaturedProduct) {
      const timer = setTimeout(() => {
        startTransition(() => setShowImageComparison(true));
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [showFeaturedProduct]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <Helmet>
        <title>Okay Artist</title>
        {/* Meta tags go here */}
      </Helmet>

      <Suspense fallback={<SlideshowSkeleton />}>
        <Header />
      </Suspense>

      <div>
        {showSlideshow ? (
          <Grow in={showSlideshow} timeout={1000}>
            <div>
              <Suspense fallback={<SlideshowSkeleton />}>
                <ImageSlideShowComponent />
              </Suspense>
            </div>
          </Grow>
        ) : (
          <SlideshowSkeleton />
        )}
      </div>

      <div>
        {showCollectionList ? (
          <Grow in={showCollectionList} timeout={1000}>
            <div>
              <Suspense fallback={<SlideshowSkeleton />}>
                <CollectionList />
              </Suspense>
            </div>
          </Grow>
        ) : (
          <SlideshowSkeleton />
        )}
      </div>

      <div>
        {showFeaturedProducts && (
          <Grow in={showFeaturedProducts} timeout={1000}>
            <div>
              <Suspense fallback={<SlideshowSkeleton />}>
                <FeaturedProductList />
              </Suspense>
            </div>
          </Grow>
        )}
      </div>

      <div>
        {showFeaturedProduct && (
          <Grow in={showFeaturedProduct} timeout={1000}>
            <div>
              <Suspense fallback={<SlideshowSkeleton />}>
                <FeaturedProductComponent />
              </Suspense>
            </div>
          </Grow>
        )}
      </div>

      <div>
        {showImageComparison && (
          <Grow in={showImageComparison} timeout={1000}>
            <div>
              <Suspense fallback={<SlideshowSkeleton />}>
                <ImageComparison />
              </Suspense>
            </div>
          </Grow>
        )}
      </div>

      <Suspense fallback={<div>Loading Footer...</div>}>
        <Footer />
      </Suspense>
    </Box>
  );
};

export default HomePage;
