import React from "react";
import { Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const PaymentSuccess = () => {
  return (
    <Container>
      {/* Meta Tags */}
      <Helmet>
        <title>Payment Successful | Okay Artist</title>
        <meta
          name="description"
          content="Thank you for your purchase! Your payment has been successfully processed."
        />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Payment Successful | Okay Artist" />
        <meta
          property="og:description"
          content="Thank you for your purchase at Okay Artist! Your payment has been successfully processed."
        />
        <meta
          property="og:url"
          content="https://www.okayartist.com/payment-success"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Payment Successful | Okay Artist" />
        <meta
          name="twitter:description"
          content="Your payment was successful. Thank you for your purchase!"
        />
      </Helmet>
      <Typography variant="h4" gutterBottom>
        Payment Successful
      </Typography>
      <Typography variant="body1">
        Thank you for your purchase! Your payment was successful.
      </Typography>
    </Container>
  );
};

export default PaymentSuccess;
