import { createSlice } from "@reduxjs/toolkit";

export const GridMenuClickedSlice = createSlice({
  name: "gridMenuClicked",
  initialState: {
    gridMenuClicked: false,
  },
  reducers: {
    setGridMenuClicked: (state) => {
      state.gridMenuClicked = true;
    },
    unsetGridMenuClicked: (state) => {
      state.gridMenuClicked = false;
    },
  },
});

export const { setGridMenuClicked, unsetGridMenuClicked } =
  GridMenuClickedSlice.actions;

export default GridMenuClickedSlice.reducer;
