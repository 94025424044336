import React, { useState, useRef, useContext } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import "./css/canvas.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

//Sample images import
import image1 from "../assets/sample-image1.jpg";
import image2 from "../assets/sample-image2.jpg";
import image3 from "../assets/sample-image3.jpg";
import image4 from "../assets/sample-image4.jpg";
import image5 from "../assets/sample-image5.jpg";
import image6 from "../assets/sample-image6.jpg";

import { useDispatch } from "react-redux";
import { setImageURL, setOriginalImageURL } from "./redux-slices/ImageSlice";
// import GrayscaleImage from "./GrayscaleImage";
import { CanvasContext } from "./CanvasContext";
import { useMediaQuery } from "@mui/material";

const itemData = [
  { id: 1, img: image1, title: "example image 1" },
  { id: 2, img: image2, title: "example image 2" },
  { id: 3, img: image3, title: "example image 3" },
  {
    id: 4,
    img: image4,
    title: "example image 4",
  },
  {
    id: 5,
    img: image5,
    title: "example image 5",
  },
  { id: 6, img: image6, title: "example image 6" },
];

const CanvasMenuItemImage = () => {
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { setImgWidth, setImgHeight } = useContext(CanvasContext);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Handle the file upload
      const url = URL.createObjectURL(file);
      dispatch(setImageURL(url)); // Dispatch action to save URL
      dispatch(setOriginalImageURL(url));

      // Create an Image object
    }
  };

  const [imgStyle, setImgStyle] = useState(true);
  const handleimageClick = () => {};
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ color: "white" }}>
        <h3 style={{ textAlign: "center" }}>Image Library</h3>
        <Stack
          direction={!matchesXS ? "column" : "row"}
          spacing={2}
          sx={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          <div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="image/*"
            />
            <Button
              onClick={handleButtonClick}
              variant="contained"
              endIcon={<FileUploadIcon />}
            >
              Upload Image
            </Button>
            {/* <GrayscaleImage /> */}
          </div>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField
              id="input-with-sx"
              label="Stock images"
              variant="standard"
              InputLabelProps={{
                style: { color: "white", opacity: "50%" }, // This changes the color of the label
              }}
              InputProps={{
                style: { color: "white" }, // This changes the color of the input text
              }}
            />
          </Box>
        </Stack>
        <ImageList
          sx={{ width: "100%", height: "auto" }}
          cols={!matchesXS ? 2 : 6}
        >
          {itemData.map((item, index) => (
            <ImageListItem key={item.index}>
              <img
                srcSet={`${item.img} 2x`} // Simplified for testing
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
                onClick={handleimageClick}
                onMouseEnter={() => {
                  setImgStyle(false);
                }}
                onMouseLeave={() => {
                  setImgStyle(true);
                }}
                // style={{ opacity: imgStyle ? "100%" : "80%" }}
                className="image-hover-effect"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </ThemeProvider>
  );
};
export default CanvasMenuItemImage;
