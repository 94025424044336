import React from "react";
import { Typography, Box } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const OrderConfirmation = ({ orderDetails }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>Order Confirmation | Okay Artist</title>
        <meta
          name="description"
          content="Thank you for your purchase! View your order details and track your order status on Okay Artist."
        />
        <meta
          name="keywords"
          content="order confirmation, order details, purchase, okay artist"
        />
        <meta property="og:title" content="Order Confirmation | Okay Artist" />
        <meta
          property="og:description"
          content="Thank you for your purchase! View your order details and track your order status on Okay Artist."
        />
        <meta
          property="og:url"
          content="https://www.okayartist.com/order-confirmation"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Order Confirmation | Okay Artist" />
        <meta
          name="twitter:description"
          content="Thank you for your purchase! You can track your order status on Okay Artist."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Header></Header>
      <Typography variant="h4" gutterBottom>
        Order Confirmation
      </Typography>
      <Typography variant="body1" gutterBottom>
        Thank you for your purchase! Your order has been successfully created.
        You can track your order status from your profile.
      </Typography>
      {/* <Typography variant="body1" gutterBottom>
        Order Details:
      </Typography>
      <Typography variant="body2" gutterBottom>
        Order ID: {orderDetails.id}
      </Typography>
      <Typography variant="body2" gutterBottom>
        Total Cost: ${orderDetails.totalOrderCost}
      </Typography> */}
      {/* Add more order details as needed */}
      <Footer></Footer>
    </Box>
  );
};

export default OrderConfirmation;
