import React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import TitleOutlinedIcon from "@mui/icons-material/TitleOutlined";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
// import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
// import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
// import FaceRetouchingNaturalOutlinedIcon from "@mui/icons-material/FaceRetouchingNaturalOutlined";
import EditIcon from "@mui/icons-material/Edit";

import { useSelector, useDispatch } from "react-redux";
import { updateCanvasMenu } from "./redux-slices/CanvasMenuSlice";
import { unsetGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";
import { useMediaQuery } from "@mui/material";
import TemplateIcon from "./Custom Icons/TemplateIcon";
import Tooltip from "@mui/material/Tooltip";

const MenuCanvas = () => {
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const selectedMenu = useSelector((state) => state.selectedMenu.value);
  const dispatch = useDispatch();
  // console.log("selectedMenu REDUX STATE= " + selectedMenu);

  const buttons = [
    <Tooltip title="Image upload" placement="bottom" arrow disableInteractive>
      <Button
        key="image"
        onClick={() => {
          dispatch(updateCanvasMenu("image"));
        }}
      >
        <PhotoOutlinedIcon />
      </Button>
    </Tooltip>,
    <Tooltip title="Editing tools" placement="bottom" arrow disableInteractive>
      <Button
        key="edit"
        onClick={() => {
          dispatch(updateCanvasMenu("edit"));
          dispatch(unsetGridMenuClicked());
        }}
      >
        <EditIcon />
      </Button>
    </Tooltip>,
    // <Tooltip title="Typography" placement="bottom" arrow disableInteractive>
    //   <Button
    //     key="text"
    //     onClick={() => {
    //       dispatch(updateCanvasMenu("text"));
    //       dispatch(unsetGridMenuClicked());
    //     }}
    //   >
    //     <TitleOutlinedIcon />
    //   </Button>
    // </Tooltip>,
    <Tooltip title="Digital Art" placement="bottom" arrow disableInteractive>
      <Button
        key="art"
        onClick={() => {
          dispatch(updateCanvasMenu("art"));
          dispatch(unsetGridMenuClicked());
        }}
      >
        <PaletteOutlinedIcon />
      </Button>
    </Tooltip>,
    // <Tooltip title="Templates" placement="bottom" arrow disableInteractive>
    //   <Button
    //     key="templates"
    //     onClick={() => {
    //       dispatch(updateCanvasMenu("templates"));
    //       dispatch(unsetGridMenuClicked());
    //     }}
    //   >
    //     {/* <PaletteOutlinedIcon /> */}
    //     <TemplateIcon />
    //   </Button>
    // </Tooltip>,
    // <Button key="overlay" onClick={() => dispatch(updateCanvasMenu("overlay"))}>
    //   <LayersOutlinedIcon />
    // </Button>,
    // <Button key="effects" onClick={() => dispatch(updateCanvasMenu("effects"))}>
    //   <AutoAwesomeOutlinedIcon />
    // </Button>,
    // <Button
    //   key="face-retouch"
    //   onClick={() => dispatch(updateCanvasMenu("face-retouch"))}
    // >
    //   <FaceRetouchingNaturalOutlinedIcon />
    // </Button>,
  ];
  return (
    <Box
      sx={{
        display: "flex",
        "& > *": {
          m: 0.3,
        },
      }}
    >
      <ButtonGroup
        orientation={matchesXS ? "horizontal" : "vertical"}
        aria-label="vertical contained button group"
        variant="contained"
        fullWidth
      >
        {buttons}
      </ButtonGroup>
    </Box>
  );
};
export default MenuCanvas;
