import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import logo from "../assets/logo.png";
import { useImageDownloader, useCanvasDownloader } from "./CanvasDownloader";
// import { fabric } from "fabric";
// import { CanvasContext } from "./CanvasContext";
import { useSelector } from "react-redux";
import { Stack, useMediaQuery } from "@mui/material";
import MobileMenu from "./MobileMenu";
import { useDispatch } from "react-redux";
import { setImageURL } from "./redux-slices/ImageSlice";
import { useNavigate } from "react-router-dom";
import { CanvasContext } from "./CanvasContext";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";

const pages = ["Open", "Save", "Exit"];
const settings = ["Profile", "Logout"];

function CanvasHeader() {
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const imageURL = useSelector((state) => state.image.imageURL); // Access the image URL from the Redux store
  const activeTemplate = useSelector((state) => state.image.activeTemplate); // Access the image URL from the Redux store
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const fileInputRef = React.useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { canvasRef, setSaveClick } = React.useContext(CanvasContext);
  // const { canvasRef } = React.useContext(CanvasContext);
  const downloadImageCanvas = useCanvasDownloader(canvasRef.current);
  const downloadImageURL = useImageDownloader();
  // const downloadImageCanvas = useCanvasDownloader();

  // const fabricCanvas = canvasRef.current.fabric;

  const handleButtonClick = (page) => {
    if (page === "Save") {
      // downloadImage("png");
      if (activeTemplate) {
        setSaveClick(true);
        downloadImageCanvas();
      }
      activeTemplate ? downloadImageCanvas() : downloadImageURL(imageURL);
    } else if (page === "Open") {
      fileInputRef.current.click();
    } else if (page === "Exit") {
      navigate("/");
    }
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMenuOpen(open);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Handle the file upload
      const url = URL.createObjectURL(file);
      dispatch(setImageURL(url)); // Dispatch action to save URL
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* Logo Image for desktop dimensions */}
            {!matchesXS && (
              <img
                src={logo}
                alt="Logo"
                variant="square"
                // sx={{ height: "10vh", display: { xs: "block", md: "none" } }}
                style={{
                  height: "10vh",
                  width: "auto",
                }}
              />
            )}

            {/* Accordion menu for mobile dimension */}
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <MenuIcon onClick={toggleDrawer(true)} />
              <MobileMenu
                menuOpen={menuOpen}
                toggleDrawer={toggleDrawer}
              ></MobileMenu>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Open, Save, and Exit menu */}

            {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}

            {/* Menu in the middle for desktop dimensions */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => {
                    handleButtonClick(page);
                  }}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page}
                </Button>
              ))}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
                accept="image/*"
              />
            </Box>

            {/* User Menu for both dimensions */}
            <Stack direction={"row"} spacing={1}>
              {/* <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
              {/* <Tooltip title="Basket">
                <IconButton
                  onClick={() => {
                    navigate("/purchase");
                  }}
                  sx={{ p: 0 }}
                >
                  <ShoppingBasketIcon></ShoppingBasketIcon>
                </IconButton>
              </Tooltip> */}
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}
export default CanvasHeader;
