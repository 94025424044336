import React from "react";
import { Box } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { setProductType } from "../redux-slices/TemplateSlice";
import { setListing } from "../redux-slices/ProductSlice";

const ProductType = () => {
  const productType = useSelector((state) => state.templates.productType);
  const dispatch = useDispatch();
  const [alignment, setAlignment] = React.useState(productType);

  const handleChangeToggleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const children = [
    <ToggleButton
      value="T-shirt"
      key="T-shirt"
      onClick={() => {
        dispatch(setProductType("T-shirt"));
      }}
    >
      <Typography>T-shirt</Typography>
    </ToggleButton>,
    <ToggleButton
      value="Sweatshirt"
      key="Sweatshirt"
      onClick={() => {
        dispatch(setProductType("Sweatshirt"));
      }}
    >
      <Typography>Sweatshirt</Typography>
    </ToggleButton>,
    <ToggleButton
      value="Hoodie"
      key="Hoodie"
      onClick={() => {
        dispatch(setProductType("Hoodie"));
      }}
    >
      <Typography>Hoodie</Typography>
    </ToggleButton>,
    <ToggleButton
      value="Tank Top"
      key="Tank Top"
      onClick={() => {
        dispatch(setProductType("Tank Top"));
      }}
    >
      <Typography>Tank Top</Typography>
    </ToggleButton>,
  ];

  const control = {
    value: alignment,
    onChange: handleChangeToggleAlignment,
    exclusive: true,
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <ToggleButtonGroup size="small" {...control} aria-label="Small sizes">
        {children}
      </ToggleButtonGroup>
    </Box>
  );
};

export default ProductType;
