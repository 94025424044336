import axios from "axios";
import { useNavigate } from "react-router-dom"; // Add this import

const axiosInstance = axios.create({
  baseURL: "http://localhost:8000/", // Your API base URL
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const navigate = useNavigate(); // Get the navigate function

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        navigate("/login"); // Redirect to login if no refresh token
        return Promise.reject(error);
      }

      try {
        const response = await axios.post(
          "http://localhost:8000/auth/jwt/refresh/",
          { refresh: refreshToken },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const { access } = response.data;
        localStorage.setItem("accessToken", access);
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access}`;
        originalRequest.headers["Authorization"] = `Bearer ${access}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        console.error(
          "Failed to refresh token:",
          err.response ? err.response.data : err.message
        );
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/login"); // Redirect to login if refresh fails
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
