import React from "react";
import { Box, Container, Stack, Typography, Divider } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {/* Meta Tags for Privacy Policy */}
      <Helmet>
        <title>Privacy Policy | Okay Artist</title>
        <meta
          name="description"
          content="Learn how Okay Artist collects, uses, and protects your personal information when you visit or make a purchase from the website."
        />
        <meta
          name="keywords"
          content="privacy policy, personal information, data collection, okay artist"
        />
        <meta property="og:title" content="Privacy Policy | Okay Artist" />
        <meta
          property="og:description"
          content="Understand how Okay Artist collects, uses, and discloses your personal information."
        />
        <meta
          property="og:url"
          content="https://www.okayartist.com/privacy-policy"
        />
        <meta
          property="og:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy | Okay Artist" />
        <meta
          name="twitter:description"
          content="Learn how Okay Artist manages your personal information when you visit or make a purchase."
        />
        <meta
          name="twitter:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
      </Helmet>
      <Header />
      <Container
        sx={{
          mt: "5%",
          mb: "5%",
        }}
      >
        <Stack
          direction={"column"}
          spacing={8}
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
          }}
        >
          <Typography variant="h4">Privacy Policy</Typography>
          <Divider orientation="horizontal" flexItem />

          <Typography variant="body1">
            This Privacy Policy describes how okayartist.com (the “Site” or
            “we”) collects, uses, and discloses your Personal Information when
            you visit or make a purchase from the Site.
          </Typography>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Collecting Personal Information
            </Typography>
            <Typography variant="body1">
              When you visit the Site, we collect certain information about your
              device, your interaction with the Site, and information necessary
              to process your purchases. We may also collect additional
              information if you contact us for customer support. In this
              Privacy Policy, we refer to any information that can uniquely
              identify an individual (including the information below) as
              “Personal Information”. See the list below for more information
              about what Personal Information we collect and why.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Device information
            </Typography>
            <Typography variant="body1">
              <strong>Examples of Personal Information collected:</strong>{" "}
              version of web browser, IP address, time zone, cookie information,
              what sites or products you view, search terms, and how you
              interact with the Site.
              <br />
              <strong>Purpose of collection:</strong> to load the Site
              accurately for you, and to perform analytics on Site usage to
              optimize our Site.
              <br />
              <strong>Source of collection:</strong> Collected automatically
              when you access our Site using cookies, log files, web beacons,
              tags, or pixels.
              <br />
              <strong>Disclosure for a business purpose:</strong> shared with
              our processor Shopify and other vendors used for analytics and
              site optimization.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Order information
            </Typography>
            <Typography variant="body1">
              <strong>Examples of Personal Information collected:</strong> name,
              billing address, shipping address, payment information (including
              credit card numbers), email address, and phone number.
              <br />
              <strong>Purpose of collection:</strong> to provide products or
              services to you to fulfill our contract, to process your payment
              information, arrange for shipping, and provide you with invoices
              and/or order confirmations, communicate with you, screen our
              orders for potential risk or fraud, and when in line with the
              preferences you have shared with us, provide you with information
              or advertising relating to our products or services.
              <br />
              <strong>Source of collection:</strong> collected from you.
              <br />
              <strong>Disclosure for a business purpose:</strong> shared with
              our processor Shopify, payment gateways, and shipping and
              fulfillment apps.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Customer support information
            </Typography>
            <Typography variant="body1">
              <strong>Examples of Personal Information collected:</strong>{" "}
              modifications to the information listed above or additional
              information as needed.
              <br />
              <strong>Purpose of collection:</strong> to provide customer
              support.
              <br />
              <strong>Source of collection:</strong> collected from you.
              <br />
              <strong>Disclosure for a business purpose:</strong> shared with
              vendors used to provide customer support.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Sharing Personal Information
            </Typography>
            <Typography variant="body1">
              We share your Personal Information with service providers to help
              us provide our services and fulfill our contracts with you, as
              described above. For example:
              <br />
              We use Shopify to power our online store. You can read more about
              how Shopify uses your Personal Information here:{" "}
              <a href="https://www.shopify.com/legal/privacy">
                https://www.shopify.com/legal/privacy
              </a>
              .
              <br />
              We may share your Personal Information to comply with applicable
              laws and regulations, to respond to a subpoena, search warrant, or
              other lawful request for information we receive, or to otherwise
              protect our rights.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Behavioural Advertising
            </Typography>
            <Typography variant="body1">
              As described above, we use your Personal Information to provide
              you with targeted advertisements or marketing communications we
              believe may be of interest to you. For example:
              <br />
              We use Google Analytics to help us understand how our customers
              use the Site. You can read more about how Google uses your
              Personal Information here:{" "}
              <a href="https://policies.google.com/privacy?hl=en">
                https://policies.google.com/privacy?hl=en
              </a>
              .
              <br />
              You can also opt-out of Google Analytics here:{" "}
              <a href="https://tools.google.com/dlpage/gaoptout">
                https://tools.google.com/dlpage/gaoptout
              </a>
              .
              <br />
              For more information about how targeted advertising works, you can
              visit the Network Advertising Initiative’s (“NAI”) educational
              page at{" "}
              <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
                http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
              </a>
              .
              <br />
              You can opt out of targeted advertising by:
              <ul>
                <li>
                  FACEBOOK -{" "}
                  <a href="https://www.facebook.com/settings/?tab=ads">
                    https://www.facebook.com/settings/?tab=ads
                  </a>
                </li>
                <li>
                  GOOGLE -{" "}
                  <a href="https://www.google.com/settings/ads/anonymous">
                    https://www.google.com/settings/ads/anonymous
                  </a>
                </li>
                <li>
                  BING -{" "}
                  <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
                    https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                  </a>
                </li>
              </ul>
              Additionally, you can opt out of some of these services by
              visiting the Digital Advertising Alliance’s opt-out portal at:{" "}
              <a href="http://optout.aboutads.info/">
                http://optout.aboutads.info/
              </a>
              .
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Using Personal Information
            </Typography>
            <Typography variant="body1">
              We use your personal Information to provide our services to you,
              which includes: offering products for sale, processing payments,
              shipping and fulfillment of your order, and keeping you up to date
              on new products, services, and offers.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Your rights
            </Typography>
            <Typography variant="body1">
              If you are a resident of the European Economic Area (“EEA”), you
              have the right to access the Personal Information we hold about
              you, to port it to a new service, and to ask that your Personal
              Information be corrected, updated, or erased. If you would like to
              exercise these rights, please contact us through the contact
              information below.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Cookies
            </Typography>
            <Typography variant="body1">
              A cookie is a small amount of information that’s downloaded to
              your computer or device when you visit our Site. We use a number
              of different cookies, including functional, performance,
              advertising, and social media or content cookies. Cookies make
              your browsing experience better by allowing the website to
              remember your actions and preferences (such as login and region
              selection). This means you don’t have to re-enter this information
              each time you return to the site or browse from one page to
              another. Cookies also provide information on how people use the
              website, for instance whether it’s their first time visiting or if
              they are a frequent visitor.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Do Not Track
            </Typography>
            <Typography variant="body1">
              Please note that because there is no consistent industry
              understanding of how to respond to “Do Not Track” signals, we do
              not alter our data collection and usage practices when we detect
              such a signal from your browser.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Changes
            </Typography>
            <Typography variant="body1">
              We may update this Privacy Policy from time to time in order to
              reflect, for example, changes to our practices or for other
              operational, legal, or regulatory reasons.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Contact
            </Typography>
            <Typography variant="body1">
              For more information about our privacy practices, if you have
              questions, or if you would like to make a complaint, please
              contact us by e-mail at support@okayartist.com or by mail using
              the details provided below:
              <br />
              Max Intel, Inc, 4283 Express Lane, Suite 7320-295, Sarasota FL
              34249, United States
            </Typography>
          </Box>
        </Stack>
      </Container>
      <Footer />
    </Box>
  );
};

export default PrivacyPolicy;
