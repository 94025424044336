import * as React from "react";
import { useState, useContext } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextEditingInterface from "../TextEditingInterface";
import TitleOutlinedIcon from "@mui/icons-material/TitleOutlined";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// import ButtonGroup from "@mui/material/ButtonGroup";
import { Stack } from "@mui/material";
import ColorizeIcon from "@mui/icons-material/Colorize";

import { SketchPicker } from "react-color";
// import { useSelector, useDispatch } from "react-redux";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
// import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CanvasContext } from "../CanvasContext";

export default function CustomTextPopover() {
  const {
    textDetails,
    setTextDetails,
    formats,
    setFormats,
    setFont,
    font,
    setTextColor,
    textColor,
    setFontSizing,
    text,
    setText,
    fontSizing,
  } = useContext(CanvasContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElColor, setAnchorElColor] = React.useState(null);
  const [fontWeight, setFontWeight] = React.useState("normal");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickColor = (event) => {
    setShowColorPicker(!showColorPicker);

    setAnchorElColor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseColor = () => {
    setAnchorElColor(null);
  };

  const open = Boolean(anchorEl);
  const openColor = Boolean(anchorElColor);
  const id = open ? "simple-popover" : undefined;
  const idColor = open ? "color-picker" : undefined;

  const theme = useTheme();
  const [fontType, setFontType] = useState("");
  // const [fontSize, setFontSize] = useState(20); // State to keep track of font size
  // const [textColor, setTextColor] = useState("#000000");
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleChange = (event) => {
    setFontType(event.target.value);
    setFont(event.target.value);
    // setFont(event.target.value);
  };

  const handleTextColorChange = (color) => {
    // const canvasRef = document.getElementById("canvas");
    setTextColor(color.hex);
  };
  // const [formats, setFormats] = React.useState(() => [
  //   "bold",
  //   "italic",
  //   "underlined",
  // ]);

  const handleFormat = (event, newFormats) => {
    console.log("BREAKPOINT newformats= " + newFormats);
    setFormats(newFormats);
    // setTextDetails({ fontWeight: newFormats });
  };

  const buttons = [<Button key="one">+</Button>, <Button key="two">-</Button>];

  const [alignment, setAlignment] = React.useState("left");

  const handleChangeToggleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const children = [
    <ToggleButton value="left" key="left">
      <FormatAlignLeftIcon fontSize="small" />
    </ToggleButton>,
    <ToggleButton value="center" key="center">
      <FormatAlignCenterIcon fontSize="small" />
    </ToggleButton>,
    <ToggleButton value="right" key="right">
      <FormatAlignRightIcon fontSize="small" />
    </ToggleButton>,
    <ToggleButton value="justify" key="justify">
      <FormatAlignJustifyIcon fontSize="small" />
    </ToggleButton>,
  ];

  const control = {
    value: alignment,
    onChange: handleChangeToggleAlignment,
    exclusive: true,
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
      <Tooltip title="Edit Text" placement="bottom" arrow disableInteractive>
        <Button
          variant="outlined"
          onClick={handleClick}
          color="primary"
          size="small"
        >
          {/* <TitleOutlinedIcon /> */}
          Adjust Text
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ padding: "20%" }}
      >
        <Box sx={{ p: 2 }}>
          {/* Apply padding here */}
          {/* <Typography>The content of the Popover.</Typography> */}

          <ThemeProvider theme={theme}>
            <Box sx={{ mb: "5%" }}>
              <TextField
                id="outlined-basic"
                label="Yout Text"
                variant="outlined"
                value={text}
                onChange={handleTextChange}
              />
            </Box>
            <Box sx={{ minWidth: 20, marginBottom: "5%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Font</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={font}
                  label="Font"
                  onChange={handleChange}
                >
                  <MenuItem
                    value={"Shrikhand"}
                    style={{ fontFamily: "Shrikhand" }}
                  >
                    Shrikhand
                  </MenuItem>
                  <MenuItem
                    value={"Freshman"}
                    style={{ fontFamily: "Freshman" }}
                  >
                    Freshman
                  </MenuItem>
                  <MenuItem
                    value={"neue-haas-grotesk-text"}
                    style={{ fontFamily: "neue-haas-grotesk-text" }}
                  >
                    Neue Haas Grotesk Text Pro 55 Roman
                  </MenuItem>
                  <MenuItem value={"choc-mn"} style={{ fontFamily: "choc-mn" }}>
                    Choc MN
                  </MenuItem>
                  <MenuItem
                    value={"ltc-broadway"}
                    style={{ fontFamily: "ltc-broadway" }}
                  >
                    LTC Broadway
                  </MenuItem>
                  <MenuItem
                    value={"big-caslon-fb"}
                    style={{ fontFamily: "big-caslon-fb" }}
                  >
                    Big Caslon CC Black
                  </MenuItem>
                  <MenuItem
                    value={"omnes-pro"}
                    style={{ fontFamily: "omnes-pro" }}
                  >
                    Omnes Black
                  </MenuItem>

                  <MenuItem
                    value={"gloria-hallelujah"}
                    style={{ fontFamily: "gloria-hallelujah" }}
                  >
                    Gloria Hallelujah
                  </MenuItem>
                  <MenuItem
                    value={"classico-urw"}
                    style={{ fontFamily: "classico-urw" }}
                  >
                    Classico URW Black
                  </MenuItem>
                  <MenuItem value={"usual"} style={{ fontFamily: "usual" }}>
                    Usual ExtraBold
                  </MenuItem>
                  <MenuItem
                    value={"cooper-black-std"}
                    style={{ fontFamily: "cooper-black-std" }}
                  >
                    Cooper Std Black
                  </MenuItem>
                  <MenuItem value={"anton"} style={{ fontFamily: "anton" }}>
                    Anton
                  </MenuItem>
                  <MenuItem
                    value={"jeff-script"}
                    style={{ fontFamily: "jeff-script" }}
                  >
                    Jeff Script
                  </MenuItem>
                  <MenuItem
                    value={"falcon-script"}
                    style={{ fontFamily: "falcon-script" }}
                  >
                    Falcon Script
                  </MenuItem>
                  <MenuItem value={"cortado"} style={{ fontFamily: "cortado" }}>
                    Cortado
                  </MenuItem>
                  <MenuItem
                    value={"gratitude-script-pro"}
                    style={{ fontFamily: "gratitude-script-pro" }}
                  >
                    Gratitude Script Pro
                  </MenuItem>
                  <MenuItem value={"amador"} style={{ fontFamily: "amador" }}>
                    Amador
                  </MenuItem>
                  <MenuItem
                    value={"sriracha"}
                    style={{ fontFamily: "sriracha" }}
                  >
                    Sriracha
                  </MenuItem>
                  <MenuItem
                    value={"yellowtail"}
                    style={{ fontFamily: "yellowtail" }}
                  >
                    Yellowtail
                  </MenuItem>
                  <MenuItem
                    value={"mrdafoe-pro"}
                    style={{ fontFamily: "mrdafoe-pro" }}
                  >
                    MrDafoe Pro
                  </MenuItem>
                  <MenuItem
                    value={"rigid-square"}
                    style={{ fontFamily: "rigid-square" }}
                  >
                    Rigid Square
                  </MenuItem>
                  <MenuItem
                    value={"haboro-contrast-normal"}
                    style={{ fontFamily: "haboro-contrast-normal" }}
                  >
                    Haboro Contrast
                  </MenuItem>
                  <MenuItem
                    value={"miller-banner"}
                    style={{ fontFamily: "miller-banner" }}
                  >
                    Miller Banner
                  </MenuItem>
                  <MenuItem
                    value={"bebas-neue"}
                    style={{ fontFamily: "bebas-neue" }}
                  >
                    Bebas Neue
                  </MenuItem>
                  <MenuItem
                    value={"novecento-slab-condensed"}
                    style={{ fontFamily: "novecento-slab-condensed" }}
                  >
                    Novecento slab condensed
                  </MenuItem>
                  <MenuItem value={"wausau"} style={{ fontFamily: "wausau" }}>
                    Wausau
                  </MenuItem>
                  <MenuItem value={"oswald"} style={{ fontFamily: "oswald" }}>
                    Oswald
                  </MenuItem>
                  <MenuItem
                    value={"funkydori"}
                    style={{ fontFamily: "funkydori" }}
                  >
                    Funkydori
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Stack
              sx={{ marginBottom: "5%" }}
              spacing={2}
              alignItems="center"
              direction={"row"}
            >
              <TextField
                id="outlined-start-adornment"
                label="Font size"
                type="number"
                value={fontSizing}
                onChange={(e) => {
                  setFontSizing(e.target.value);
                  // console.log("fontSize= " + fontSize);
                }}
                InputLabelProps={{
                  shrink: true,
                  startAdornment: (
                    <InputAdornment position="start">kg</InputAdornment>
                  ),
                }}
              />
              <Button onClick={handleClickColor}>
                <ColorizeIcon></ColorizeIcon>
              </Button>
              <Popover
                id={idColor}
                open={openColor}
                anchorEl={anchorElColor}
                onClose={handleCloseColor}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <SketchPicker
                  color={textColor}
                  onChangeComplete={handleTextColorChange}
                />
              </Popover>
            </Stack>
            <Stack sx={{ marginBottom: "5%" }} spacing={2} alignItems="center">
              <ToggleButtonGroup
                value={formats}
                onChange={handleFormat}
                aria-label="text formatting"
              >
                <ToggleButton
                  value="bold"
                  aria-label="bold"
                  onClick={(event, value) => {
                    setFontWeight(value);
                    // console.log("BREAKPOINT bold= " + value);
                    // setTextDetails({ fontWeight: fontWeight });
                  }}
                >
                  <FormatBoldIcon />
                </ToggleButton>
                <ToggleButton value="italic" aria-label="italic">
                  <FormatItalicIcon />
                </ToggleButton>
                <ToggleButton value="underlined" aria-label="underlined">
                  <FormatUnderlinedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Stack spacing={2} alignItems="center">
              <ToggleButtonGroup
                size="small"
                {...control}
                aria-label="Small sizes"
              >
                {children}
              </ToggleButtonGroup>
            </Stack>
          </ThemeProvider>
        </Box>
      </Popover>
    </div>
  );
}
