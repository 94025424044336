import axios from "axios";

let urlImage;
function getCsrfToken() {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith("csrftoken="))
    ?.split("=")[1];
}

// export const oilPainting = async (imageURL) => {
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");

//       return axios.post(
//         "http://localhost:8000/api/oil_painting_effect/",
//         formData,
//         {
//           responseType: "blob",
//         }
//       );
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error uploading image:", error);
//       throw error; // Important to propagate the error
//     });
// };

// export const sketchStyle = async (imageURL) => {
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");

//       return axios.post("http://localhost:8000/api/sketch_effect/", formData, {
//         responseType: "blob",
//       });
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error uploading image:", error);
//       throw error; // Important to propagate the error
//     });
// };

// export const cartoonizerEffect = async (imageURL) => {
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");

//       return axios.post(
//         "http://localhost:8000/api/cartoonizer_effect/",
//         formData,
//         {
//           responseType: "blob",
//         }
//       );
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error uploading image:", error);
//       throw error; // Important to propagate the error
//     });
// };

// export const watercolorEffect = async (imageURL) => {
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");

//       return axios.post(
//         "http://localhost:8000/api/watercolor_effect/",
//         formData,
//         {
//           responseType: "blob",
//         }
//       );
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error uploading image:", error);
//       throw error; // Important to propagate the error
//     });
// };

export const enhanceImage = async (imageURL) => {
  const token = localStorage.getItem("accessToken");
  // const csrfToken = getCsrfToken();
  console.log("BREAKPOINT imageURL= " + imageURL);

  try {
    // Retrieve the image as a blob using axios
    const imageResponse = await axios.get(imageURL, { responseType: "blob" });
    const blob = imageResponse.data;

    const formData = new FormData();
    formData.append("image", blob, "image.jpg");

    // Post the image blob to your backend
    const response = await axios.post(
      "https://api.okayartist.com/api/enhance_image/",
      formData,
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          // "X-CSRFToken": csrfToken,
        },
      }
    );

    // Create and return the URL for the enhanced image
    const url = URL.createObjectURL(response.data);
    return url;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error; // Propagate the error
  }
};

export const resizeImage = async (
  imageURL,
  height,
  width,
  maintainAspectRatio
) => {
  // Retrieve the token from storage
  const token = localStorage.getItem("accessToken"); // Adjust based on where you store the token
  // const csrfToken = getCsrfToken();

  return fetch(imageURL)
    .then((res) => res.blob())
    .then((blob) => {
      const formData = new FormData();
      formData.append("image", blob, "image.jpg");
      formData.append("height", height);
      formData.append("width", width);
      formData.append("maintainAspectRatio", maintainAspectRatio);

      return axios.post(
        "https://api.okayartist.com/api/resize_image/",
        formData,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
          // "X-CSRFToken": csrfToken, // Django expects the token in this header
        }
      );
    })
    .then((response) => {
      const url = URL.createObjectURL(response.data);
      return url;
    })
    .catch((error) => {
      console.error("Error resizing image:", error);
      throw error;
    });
};

export const rotateImage = async (imageURL, rotation, flip, slider_angle) => {
  // Retrieve the token from storage
  const token = localStorage.getItem("accessToken"); // Adjust based on where you store the token
  // const csrfToken = getCsrfToken();

  return fetch(imageURL)
    .then((res) => res.blob())
    .then((blob) => {
      const formData = new FormData();
      formData.append("image", blob, "image.jpg");
      formData.append("rotation", rotation);
      formData.append("flip", flip);
      formData.append("slider_angle", slider_angle);

      return axios.post(
        "https://api.okayartist.com/api/rotate_image/",
        formData,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
          // "X-CSRFToken": csrfToken, // Django expects the token in this header
        }
      );
    })
    .then((response) => {
      const url = URL.createObjectURL(response.data);
      return url;
    })
    .catch((error) => {
      console.error("Error resizing image:", error);
      throw error;
    });
};

// export const cropImage = async (imageURL, x, y, width, height) => {
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");
//       formData.append("x", x);
//       formData.append("y", y);
//       formData.append("width", width);
//       formData.append("height", height);

//       return axios.post("http://localhost:8000/api/crop_image/", formData, {
//         responseType: "blob",
//       });
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error resizing image:", error);
//       throw error;
//     });
// };

// export const removeBackground = async (imageURL) => {
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");

//       return axios.post(
//         "http://localhost:8000/api/remove_background/",
//         formData,
//         {
//           responseType: "blob",
//         }
//       );
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error uploading image:", error);
//       throw error; // Important to propagate the error
//     });
// };

// export const removeBackgroundAI = async (imageURL) => {
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");

//       return axios.post(
//         "http://localhost:8000/api/remove_background_dl/",
//         formData,
//         {
//           responseType: "blob",
//         }
//       );
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error uploading image:", error);
//       throw error; // Important to propagate the error
//     });
// };
// export const removeBackgroundAI2 = async (imageURL) => {
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");

//       return axios.post(
//         "http://localhost:8000/api/remove_background_dl2/",
//         formData,
//         {
//           responseType: "blob",
//         }
//       );
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error uploading image:", error);
//       throw error; // Important to propagate the error
//     });
// };

// export const styleTransfer = async (imageURL) => {
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");

//       return axios.post("http://localhost:8000/api/style_transfer/", formData, {
//         responseType: "blob",
//       });
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error uploading image:", error);
//       throw error; // Important to propagate the error
//     });
// };

/// Line Art Like The Photoshop version
// export const lineArt = async (imageURL) => {
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");

//       return axios.post("http://localhost:8000/api/line_art/", formData, {
//         responseType: "blob",
//       });
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error uploading image:", error);
//       throw error; // Important to propagate the error
//     });
// };

export const cartoonizerEffect2 = async (imageURL) => {
  return fetch(imageURL)
    .then((res) => res.blob())
    .then((blob) => {
      const formData = new FormData();
      formData.append("image", blob, "image.jpg");

      return axios.post(
        // "https://api.okayartist.com/api/convert-to-cartoon-ai/",
        "http://127.0.0.1:8000/api/convert-to-cartoon-ai/",
        formData,
        {
          responseType: "blob",
        }
      );
    })
    .then((response) => {
      const url = URL.createObjectURL(response.data);
      return url;
    })
    .catch((error) => {
      console.error("Error uploading image:", error);
      throw error; // Important to propagate the error
    });
};

// export const styleTransfer2 = async (imageURL) => {
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");

//       return axios.post(
//         "http://localhost:8000/api/style_transfer2/",
//         formData,
//         {
//           responseType: "blob",
//         }
//       );
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error uploading image:", error);
//       throw error; // Important to propagate the error
//     });
// };

export const imageToVectorBlackAndWhite = async (imageURL) => {
  // Retrieve the token from storage
  const token = localStorage.getItem("accessToken"); // Adjust based on where you store the token
  // const csrfToken = getCsrfToken();
  // if (csrfToken) console.log("csrfToken is found " + csrfToken);
  // else console.log("csrfToken not found " + csrfToken);
  return fetch(imageURL)
    .then((res) => res.blob())
    .then((blob) => {
      const formData = new FormData();
      formData.append("image", blob, "image.jpg");

      return axios.post(
        "https://api.okayartist.com/api/image_to_vector_grayscale/",
        // "http://127.0.0.1:8000/api/image_to_vector_grayscale/",
        formData,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            // "X-CSRFToken": csrfToken, // Django expects the token in this header },
          },
        }
      );
    })
    .then((response) => {
      const url = URL.createObjectURL(response.data);
      return url;
    })
    .catch((error) => {
      console.error("Error uploading image:", error);
      throw error; // Important to propagate the error
    });
};

export const imageToVectorBlackAndWhite2 = async (imageURL) => {
  // Retrieve the token from storage
  const token = localStorage.getItem("accessToken"); // Adjust based on where you store the token
  // const csrfToken = getCsrfToken();
  // if (csrfToken) console.log("csrfToken is found " + csrfToken);
  // else console.log("csrfToken not found " + csrfToken);
  return fetch(imageURL)
    .then((res) => res.blob())
    .then((blob) => {
      const formData = new FormData();
      formData.append("image", blob, "image.jpg");

      return axios.post(
        "https://api.okayartist.com/api/image_to_vector_grayscale2/",
        formData,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            // "X-CSRFToken": csrfToken, // Django expects the token in this header },
          },
        }
      );
    })
    .then((response) => {
      const url = URL.createObjectURL(response.data);
      return url;
    })
    .catch((error) => {
      console.error("Error uploading image:", error);
      throw error; // Important to propagate the error
    });
};

// export const backgroundRemoverREMBG = async (imageURL) => {
//   // Retrieve the token from storage
//   const token = localStorage.getItem("accessToken"); // Adjust based on where you store the token
//   // const csrfToken = getCsrfToken();
//   return fetch(imageURL)
//     .then((res) => res.blob())
//     .then((blob) => {
//       const formData = new FormData();
//       formData.append("image", blob, "image.jpg");

//       return axios.post(
//         "https://api.okayartist.com/api/remove_background_rembg/",
//         // "http://127.0.0.1:8000/api/remove_background_rembg/",
//         formData,
//         {
//           responseType: "blob",
//           headers: { Authorization: `Bearer ${token}` },
//           // "X-CSRFToken": csrfToken, // Django expects the token in this header
//         }
//       );
//     })
//     .then((response) => {
//       const url = URL.createObjectURL(response.data);
//       return url;
//     })
//     .catch((error) => {
//       console.error("Error uploading image:", error);
//       throw error; // Important to propagate the error
//     });
// };

export const backgroundRemoverREMBG = async (imageURL) => {
  try {
    // Fetch the image from the URL
    const response = await fetch(imageURL);
    const blob = await response.blob();

    // Convert the image to base64
    const base64Image = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

    console.log("Base64 Image:", base64Image); // Log base64 image

    // Create the request body
    const requestBody = JSON.stringify({
      image: base64Image,
    });

    // Create a controller to handle fetch timeout
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 seconds timeout

    // Send the image to the Lambda endpoint
    const result = await fetch(
      "https://hsjarl4em9.execute-api.us-east-1.amazonaws.com/prod/remove_background",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
        signal: controller.signal, // Attach the signal to the fetch request
      }
    );

    // Clear the timeout when the fetch completes
    clearTimeout(timeoutId);

    if (!result.ok) {
      throw new Error(`HTTP error! status: ${result.status}`);
    }

    const data = await result.json();

    console.log("Lambda Response Data:", data); // Log Lambda response

    // Parse the body to get the image data
    const responseBody = JSON.parse(data.body);
    if (!responseBody.image) {
      throw new Error("The response does not contain the image data.");
    }

    // Decode the base64 image received from the Lambda function
    const outputBlob = await fetch(
      `data:image/png;base64,${responseBody.image}`
    ).then((res) => res.blob());
    const url = URL.createObjectURL(outputBlob);

    return url;
  } catch (error) {
    if (error.name === "AbortError") {
      console.error("Fetch request timed out");
    } else {
      console.error("Error processing image:", error);
    }
    throw error; // Important to propagate the error
  }
};

export const backgroundRemoverREMBGGoogle = async (imageURL) => {
  try {
    // Fetch the image from the URL
    const response = await fetch(imageURL);
    const blob = await response.blob();

    // Create a FormData object to hold the image file
    const formData = new FormData();
    formData.append("image", blob, "image.png");

    // Create a controller to handle fetch timeout
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 20000); // 30 seconds timeout

    // Send the image to the Cloud Run endpoint
    const result = await fetch(
      "https://rembg-app-lh3hdxifra-ue.a.run.app/remove-bg",
      {
        method: "POST",
        body: formData,
        signal: controller.signal, // Attach the signal to the fetch request
      }
    );

    // Clear the timeout when the fetch completes
    clearTimeout(timeoutId);

    if (!result.ok) {
      throw new Error(`HTTP error! status: ${result.status}`);
    }

    // Convert the response to a Blob
    const outputBlob = await result.blob();
    const url = URL.createObjectURL(outputBlob);

    return url;
  } catch (error) {
    if (error.name === "AbortError") {
      console.error("Fetch request timed out");
    } else {
      console.error("Error processing image:", error);
    }
    throw error; // Important to propagate the error
  }
};

// export const backgroundRemoverREMBG = async (imageURL) => {
//   try {
//     // Retrieve the token from storage
//     const token = localStorage.getItem("accessToken"); // Adjust based on where you store the token

//     // Fetch the image blob from the imageURL
//     const imageResponse = await fetch(imageURL);
//     if (!imageResponse.ok) {
//       throw new Error("Failed to fetch image from URL");
//     }

//     const blob = await imageResponse.blob();
//     const formData = new FormData();
//     formData.append("image", blob, "image.jpg");

//     // Send the image to the background removal API
//     const response = await fetch(
//       "https://hsjarl4em9.execute-api.us-east-1.amazonaws.com/prod/remove_background",
//       // " http://127.0.0.1:8000/api/remove_background_rembg/",
//       {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         body: formData,
//       }
//     );

//     if (!response.ok) {
//       const errorText = await response.text();
//       throw new Error(`Network response was not ok: ${errorText}`);
//     }

//     console.log("BREAKPOINT response= " + response);
//     const data = await response.json();
//     const base64Image = data.body;
//     const url = `data:image/png;base64,${base64Image}`;
//     return url;
//   } catch (error) {
//     console.error("Error uploading image:", error);
//     throw error; // Important to propagate the error
//   }
// };

// //hello world lambda testing function
// export const backgroundRemoverREMBG = async () => {
//   try {
//     const response = await fetch(
//       "https://raqln5nyl0.execute-api.us-east-1.amazonaws.com/prod/hello-world",
//       {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: "Bearer your_token_if_any", // Adjust this if you use authentication
//         },
//       }
//     );

//     if (!response.ok) {
//       throw new Error("Network response was not ok");
//     }

//     const data = await response.text();
//     console.log(data); // 'Hello, World!1'
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   }
// };

export const convertToSketchOpencv = async (imageURL) => {
  return fetch(imageURL)
    .then((res) => res.blob())
    .then((blob) => {
      const formData = new FormData();
      formData.append("image", blob, "image.jpg");

      return axios.post(
        "https://api.okayartist.com/api/convert-to-sketch-opencv/",
        formData,
        {
          responseType: "blob",
        }
      );
    })
    .then((response) => {
      const url = URL.createObjectURL(response.data);
      return url;
    })
    .catch((error) => {
      console.error("Error uploading image:", error);
      throw error; // Important to propagate the error
    });
};

export const convertToSketch2 = async (imageURL) => {
  return fetch(imageURL)
    .then((res) => res.blob())
    .then((blob) => {
      const formData = new FormData();
      formData.append("image", blob, "image.jpg");

      return axios.post(
        "https://api.okayartist.com/api/convert-to-sketch-2/",
        formData,
        {
          responseType: "blob",
        }
      );
    })
    .then((response) => {
      const url = URL.createObjectURL(response.data);
      return url;
    })
    .catch((error) => {
      console.error("Error uploading image:", error);
      throw error; // Important to propagate the error
    });
};
