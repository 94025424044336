import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [], // This will store each item with its properties
  lastId: 0, // Initialize the counter for unique IDs
  itemsTotalCost: 0,
  orderTotalCost: 0,
  shippingTotal: 0,
  itemsQuantity: 0,
};

export const basketSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const { id, size, color, quantity, totalPrice } = action.payload;

      // Check if the item already exists in the cart
      const existingItem = state.items.find(
        (item) => item.id === id && item.size === size && item.color === color
      );

      if (existingItem) {
        // If item exists, increase its quantity
        // console.log("BREAKPOINT existingItem= " + existingItem);
        existingItem.quantity += quantity;
        existingItem.totalPrice += totalPrice;
      } else {
        // Increment the counter for the new item's unique cart ID
        state.lastId += 1;

        // Add the new item with a unique cart ID
        state.items.push({ ...action.payload, cartId: state.lastId });
      }
      state.itemsQuantity = state.items.length;
    },
    removeItem: (state, action) => {
      // Remove item from basket
      state.items = state.items.filter((item) => item.id !== action.payload.id);
      state.itemsQuantity = state.items.length;
    },

    updateQuantity: (state, action) => {
      // Update the quantity of an existing item
      const item = state.items.find((item) => item.id === action.payload.id);
      if (item) {
        item.quantity = action.payload.quantity;

        //total price is changed
        const price = item.listing.sizes.find(
          (element) => element.size === item.size
        ).price;
        item.totalPrice = item.quantity * price;
      }
    },
    clearBasket: (state) => {
      // Clear all items from the basket
      state.items = [];
      state.itemsQuantity = state.items.length;
    },
    setTotalOrderCost: (state) => {
      state.itemsTotalCost = 0;
      state.shippingTotal = 0;
      state.orderTotalCost = 0;
      state.items.forEach((element) => {
        state.itemsTotalCost += element.totalPrice;
        state.shippingTotal +=
          element.quantity * element.listing.shippingRates.firstItem;
        // console.log(
        //   "BREAKPOINT shippingTotal= " + element.listing.shippingRates.firstItem
        // );
      });
      state.orderTotalCost = state.shippingTotal + state.itemsTotalCost;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  addItem,
  removeItem,
  updateQuantity,
  clearBasket,
  setTotalOrderCost,
} = basketSlice.actions;

export default basketSlice.reducer;
