import React from "react";
import Header from "./Header";
import ProductComponent from "./ProductComponent";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import theme from "../theme";
import Footer from "./Footer";
import { Box } from "@mui/material";
import ProductLandingComponent from "./ProductLandingComponent";

const CustomProductPage = () => {
  // const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <ProductComponent />

        <Footer></Footer>
      </Box>
    </ThemeProvider>
  );
};

export default CustomProductPage;
