import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
let retryCount = 0;
const maxRetries = 3;

// Create an Axios instance
const api = axios.create({
  // baseURL: "http://127.0.0.1:8000", // Change this to your API base URL
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const logoutUser = () => {
  // Remove tokens from local storage
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");

  // Redirect user to login page or display a logout message
  window.location.href = "/login"; // Adjust to your app’s login route
};

// Function to refresh the token
async function refreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  try {
    const response = await api.post("/auth/jwt/refresh/", {
      refresh: refreshToken,
    });
    retryCount = 0; // Reset retry counter on success
    const { access } = response.data;
    localStorage.setItem("accessToken", access);
    return access;
  } catch (error) {
    if (retryCount >= maxRetries) {
      // setIsAuthenticated(false);
      logoutUser(); // Optional logout or error handling here
      return;
    }
    retryCount++;
    console.log(`Retry attempt ${retryCount}`);
    await new Promise((res) => setTimeout(res, 1000)); // 1 second delay
    return refreshToken(); // Recursive retry
  }
}

// axiosInstance.interceptors.request.use(
//   (request) => {
//     const accessToken = localStorage.getItem("accessToken");
//     if (accessToken) {
//       request.headers["Authorization"] = `Bearer ${accessToken}`;
//     }
//     return request;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Axios interceptor to handle token expiration
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshToken();
        api.defaults.headers.common["Authorization"] =
          "Bearer " + newAccessToken;
        originalRequest.headers["Authorization"] = "Bearer " + newAccessToken;
        return api(originalRequest);
      } catch (error) {
        console.error("Token refresh failed:", error);
        logoutUser();
        // Redirect to login page or handle as needed
        // window.location.href = "/login"; // Uncomment to redirect to login on failure
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

// Set the Authorization header if the token exists
const accessToken = localStorage.getItem("accessToken");
if (accessToken) {
  api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}

export default api;
