import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import PreviewIcon from "@mui/icons-material/Preview";
import { IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShirtMockup from "./ShirtMockup";
import ProductType from "./ProductType";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { setProductType } from "../redux-slices/TemplateSlice";
import SweatshirtMockup from "./SweatshirtMockup";
import ProductMockup from "./ProductMockup";
import {
  imagesBellaCanvas3408,
  imagesBellaCanvas3408Fabric,
  imagesGildan18500,
  imagesGildan18500Fabric,
  imagesBellaCanvas3001,
  imagesBellaCanvas3001Fabric,
  imagesGildan18000,
  imagesGildan18000Fabric,
} from "./images";

import { useImageDownloader, useCanvasDownloader } from "../CanvasDownloader";
import DownloadIcon from "@mui/icons-material/Download";
import { Link } from "react-router-dom";
import { setBasketProduct, setListing } from "../redux-slices/ProductSlice";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const productDetails = [
  {
    product: "T-shirt",
    title: "Unisex T-shirt, Cotton T-shirt, Bella Canvas 3001",
    description: `-----Unisex Jersey Short Sleeve Tee Details-----
    This classic unisex jersey short-sleeve tee fits like a well-loved favorite. Soft cotton and quality print make users fall in love with it over and over again. These t-shirts have-ribbed knit collars to bolster shaping. The shoulders have taping for better fit over time. Dual side seams hold the garment's shape for longer.
    -100% Airlume combed and ringspun cotton (fiber content may vary for different colors)
    -Light fabric
    -Retail fit
    -Tear away label
    -Runs true to size`,
    brand: "Bella Canvas 3001",
    listingId: "tshirtbellacanvas3001",
    unitPrice: [
      { small: 25 },
      { medium: 25 },
      { large: 25 },
      { xlarge: 25 },
      { doublexlarge: 30 },
      { triplexlarge: 35 },
    ],
    sizes: [
      { size: "S", price: 25 },
      { size: "M", price: 25 },
      { size: "L", price: 25 },
      { size: "XL", price: 25 },
      { size: "2XL", price: 28 },
      { size: "3XL", price: 30 },
    ],
    shippingRates: { firstItem: 5, extraItem: 3 },
  },
  {
    product: "Hoodie",
    title: "Unisex Pullover Hoodie, Cotton Blend Hoodie, Gildan 18500",
    description: `Unisex Hoodie Material details:
  A unisex heavy blend hooded sweatshirt is relaxation itself. The material is a thick blend of cotton and polyester. This makes for a plush, soft feel alongside warmth. It's also a great surface for printing. There are no side seams. A spacious kangaroo pocket hangs in front. The hood's drawstring is the same color as the base sweater.
  -50% Cotton 50% Polyester
  -Medium-heavy fabric
  -Classic fit
  -Tear away label
  -Runs true to size`,
    brand: "Gildan 18500",
    listingId: "hoodiegildan18500",
    unitPrice: [
      { small: 25 },
      { medium: 25 },
      { large: 25 },
      { xlarge: 25 },
      { doublexlarge: 30 },
      { triplexlarge: 35 },
    ],
    sizes: [
      { size: "S", price: 25 },
      { size: "M", price: 25 },
      { size: "L", price: 25 },
      { size: "XL", price: 25 },
      { size: "2XL", price: 28 },
      { size: "3XL", price: 30 },
    ],
    shippingRates: { firstItem: 7, extraItem: 4 },
  },
  {
    product: "Sweatshirt",
    title: "Unisex Crewneck Sweatshirt, Cotton Blend Sweatshirt, Gildan 18000",
    description: `Unisex Sweatshirt Material details:
  This soft sweatshirt has a loose fit for a comfortable feel. With durable print, it will be a walking billboard for years to come.
  -Loose fit
  -50% Cotton; 50% Polyester
  -Medium fabric
  -Sewn in label
  -Runs true to size`,
    brand: "Gildan 18000",
    listingId: "sweatshirtgildan18000",
    unitPrice: [
      { small: 25 },
      { medium: 25 },
      { large: 25 },
      { xlarge: 25 },
      { doublexlarge: 30 },
      { triplexlarge: 35 },
    ],
    sizes: [
      { size: "S", price: 25 },
      { size: "M", price: 25 },
      { size: "L", price: 25 },
      { size: "XL", price: 25 },
      { size: "2XL", price: 28 },
      { size: "3XL", price: 30 },
    ],
    shippingRates: { firstItem: 7, extraItem: 4 },
  },
  {
    product: "Tank Top",
    title: "Unisex tank top, Cotton Tank top, Bella Canvas 3480",
    description: `The tank top for a quick workout! This tank top is made of a stretchy, lightweight material that is both breathable and comfortable. The material is not see-through, so you don't have to worry about embarrassing moments. It is made of high-quality materials that are guaranteed to last for a long time. This tank top is perfect for hot days because it is breathable and lightweight.

    🎽🎽🎽🎽🎽 Tank Top Details: 🎽🎽🎽🎽🎽
    -Extra Light fabric
    -Retail fit
    -Sewn-in label
    -Runs true to size
    -100% Airlume combed and ring-spun cotton (may change due to colors)`,
    brand: "Bella Canvas 3480",
    listingId: "tanktopbellacanvas3480",
    unitPrice: [
      { small: 25 },
      { medium: 25 },
      { large: 25 },
      { xlarge: 25 },
      { doublexlarge: 30 },
    ],
    sizes: [
      { size: "S", price: 25 },
      { size: "M", price: 25 },
      { size: "L", price: 25 },
      { size: "XL", price: 25 },
      { size: "2XL", price: 28 },
      { size: "3XL", price: 30 },
    ],
    shippingRates: { firstItem: 5, extraItem: 3 },
  },
];

export default function ViewMockup() {
  const downloadImageURL = useImageDownloader();
  const dispatch = useDispatch();
  const productType = useSelector((state) => state.templates.productType);
  const imgMockupURL = useSelector((state) => state.image.imgMockupURL);

  console.log("BREAKPOINT inside ViewMockup productType= " + productType);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    dispatch(setProductType("T-shirt"));
  };

  const [product, setProduct] = useState(null);
  // const [productDetails, setProductDetails] = useState([]);

  //take product listing from dynamodb
  // useEffect(() => {
  //   axios
  //     .get("http://localhost:8000/api/products/")
  //     .then((response) => {
  //       setProductDetails(response.data);
  //       console.log(
  //         "BREAKPOINT inside fetch dynamodb producst table= " +
  //           JSON.stringify(response.data)
  //       );
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching products:", error);
  //     });
  // }, []);

  const updateProduct = (name, value) => {
    dispatch(setBasketProduct({ name, value }));
  };
  const updateListing = (name, value) => {
    dispatch(setListing({ name, value }));
  };

  useEffect(() => {
    updateProduct("productType", productType);
    const filtered = productDetails.find(
      (product) => product.product === productType
    );
    updateListing("title", filtered["title"]);
    updateListing("description", filtered.description);
    updateListing("brand", filtered.brand);
    updateListing("listingId", filtered.listingId);
    updateListing("unitPrice", filtered.unitPrice);
    updateListing("sizes", filtered.sizes);
    updateListing("shippingRates", filtered.shippingRates);
  }, [productType, productDetails]);

  useEffect(() => {
    if (productType === "T-shirt") {
      setProduct(
        <ProductMockup
          productType={"T-Shirt"}
          imagesProduct={imagesBellaCanvas3001}
          imagesFabric={imagesBellaCanvas3001Fabric}
        />
      );
    } else if (productType === "Sweatshirt")
      setProduct(
        <ProductMockup
          productType={"Sweatshirt"}
          imagesProduct={imagesGildan18000}
          imagesFabric={imagesGildan18000Fabric}
        />
      );
    else if (productType === "Hoodie")
      setProduct(
        <ProductMockup
          productType={"Hoodie"}
          imagesProduct={imagesGildan18500}
          imagesFabric={imagesGildan18500Fabric}
        />
      );
    else if (productType === "Tank Top")
      setProduct(
        <ProductMockup
          productType={"Tank Top"}
          imagesProduct={imagesBellaCanvas3408}
          imagesFabric={imagesBellaCanvas3408Fabric}
        />
      );
    else {
      // setProduct(<ShirtMockup />);
    }
    return () => {
      // dispatch(setProductType("T-shirt"));
    };
  }, [productType]);
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Tooltip title="View Mockup" placement="bottom" arrow disableInteractive>
        <IconButton onClick={handleOpen} color="primary">
          <PreviewIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction={"column"} spacing={1}>
            {/* <ShirtMockup /> */}
            <ProductType />
            {product}
            <Stack direction={"row"} spacing={1} justifyContent={"end"}>
              <Button variant="outlined" onClick={() => {}}>
                <Link
                  to="/product-custom"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Purchase
                </Link>
              </Button>
              {/* <Button variant="outlined" onClick={() => {}}>
                Add to Basket
              </Button> */}
              <Button
                variant="text"
                onClick={() => {
                  downloadImageURL(imgMockupURL);
                }}
              >
                <Tooltip title="Download Mockup">
                  <DownloadIcon></DownloadIcon>
                </Tooltip>

                {/* down */}
              </Button>

              <Button variant="text" onClick={handleClose}>
                <Tooltip title="Close">
                  <CloseIcon></CloseIcon>
                </Tooltip>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
