import React from "react";
import { Box, IconButton, ImageList, ImageListItem } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { motion, AnimatePresence } from "framer-motion";
import { fetchCoreProductDetails } from "./fetchProductDetails";

const ProductImageComponent = ({
  images,
  productImg,
  setProductImg,
  matchesXS,
  brandName,
}) => {
  const imagesQuantity = images.length;
  const [activeImg, setActiveImg] = React.useState(0);
  const [dragging, setDragging] = React.useState(false);
  const [direction, setDirection] = React.useState(0); // To track the drag direction (1 for right, -1 for left)
  const [coreProduct, setCoreProduct] = React.useState([]);
  const [preloadedImages, setPreloadedImages] = React.useState([]); //
  // const [images, setProductImages] = React.useState(images);

  const handlePrevClick = () => {
    setDirection(-1);
    setActiveImg((prevActiveImg) =>
      prevActiveImg > 0 ? prevActiveImg - 1 : images.length - 1
    );
  };

  const handleNextClick = () => {
    setDirection(1);
    setActiveImg((prevActiveImg) =>
      prevActiveImg < images.length - 1 ? prevActiveImg + 1 : 0
    );
  };

  React.useEffect(() => {
    setProductImg(images[activeImg].img);
  }, [activeImg]);

  const handleDragEnd = (event, info) => {
    if (info.offset.x > 100) {
      setDirection(1);
      handlePrevClick();
    } else if (info.offset.x < -100) {
      setDirection(-1);
      handleNextClick();
    }
    setDragging(false);
  };

  const variants = {
    enter: (direction) => ({
      x: direction === 1 ? 500 : -500,
      opacity: 0,
      scale: 0.8,
    }),
    center: {
      x: 0,
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
    exit: (direction) => ({
      x: direction === 1 ? -500 : 500,
      opacity: 0,
      scale: 0.8,
      transition: { duration: 0.5, ease: "easeInOut" },
    }),
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: !matchesXS ? "700px" : "400px", // Fixed height for the main image
          overflow: "hidden", // Ensure it does not overflow
        }}
      >
        <AnimatePresence initial={false} custom={direction} mode="wait">
          <motion.img
            key={productImg}
            src={productImg}
            style={{ height: "80%", width: "auto" }} // Maintain aspect ratio
            alt="mockup image of custom order"
            loading="lazy"
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            drag="x" // Allow horizontal dragging
            dragConstraints={{ left: 0, right: 0 }} // Limit dragging to horizontal axis
            onDragStart={() => setDragging(true)} // Track dragging state
            onDragEnd={handleDragEnd} // Handle drag end
          />
        </AnimatePresence>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <IconButton onClick={handlePrevClick} disabled={dragging}>
          <WestIcon />
        </IconButton>

        {images.map((image, index) => (
          <IconButton
            key={index}
            onClick={() => setActiveImg(index)}
            disabled={dragging}
          >
            {index === activeImg ? (
              <RadioButtonCheckedIcon fontSize="small" />
            ) : (
              <RadioButtonUncheckedIcon fontSize="small" />
            )}
          </IconButton>
        ))}

        <IconButton onClick={handleNextClick} disabled={dragging}>
          <EastIcon />
        </IconButton>
      </Box>

      {!matchesXS && (
        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
          <ImageList
            sx={{
              flexDirection: "row",
              display: "flex",
            }}
            cols={images.length}
            rowHeight={200}
          >
            {images.map((item, index) => (
              <ImageListItem
                key={index}
                sx={{
                  width: "200px !important",
                  cursor: "pointer",
                  "&:hover": {
                    opacity: 0.7,
                    transition: "transform 0.2s",
                  },
                }}
                onClick={() => {
                  setProductImg(item.img);
                  setActiveImg(index);
                }}
              >
                <img
                  src={`${item.img}?w=200&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=200&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}
    </>
  );
};

export default ProductImageComponent;
