import React from "react";
import { Box, Typography, Container, Stack } from "@mui/material";

import { Grid, Link } from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        // position: "absolute",
        // bottom: 0,
        // width: "100%",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          // justifyContent="center"
          // alignItems="center"
          sx={{ textAlign: "center" }}
          columns={{ xs: 1, sm: 8, md: 12 }}
        >
          <Grid item xs={1} sm={2} md={3}>
            <Typography variant="h6" gutterBottom>
              COMPANY
            </Typography>
            <Typography variant="body2">
              <Link href="/about" color="inherit" underline="hover">
                About Us
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="/privacy-policy" color="inherit" underline="hover">
                Privacy Policy
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="/refund-policy" color="inherit" underline="hover">
                Refund Policy
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={1} sm={2} md={3}>
            {/* <Typography variant="h6" gutterBottom>
              COMPANY
            </Typography> */}
            <Typography variant="body2">
              <Link href="/terms-of-use" color="inherit" underline="hover">
                Terms of Service
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="/shipping-policy" color="inherit" underline="hover">
                Shipping Policy
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link
                href="/contact-information"
                color="inherit"
                underline="hover"
              >
                Contact Information
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={1} sm={2} md={3}>
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography variant="h6" gutterBottom>
                FOLLOW US
              </Typography>
              <Stack direction={"row"} spacing={1}>
                <Typography variant="body2">
                  <Link href="/about-us" color="inherit" underline="hover">
                    <FacebookIcon />
                  </Link>
                </Typography>
                <Typography variant="body2">
                  <Link
                    href="https://www.instagram.com/okayartistofficial/"
                    color="inherit"
                    underline="hover"
                  >
                    <InstagramIcon></InstagramIcon>
                  </Link>
                </Typography>
                <Typography variant="body2">
                  <Link href="/refund-policy" color="inherit" underline="hover">
                    <YouTubeIcon></YouTubeIcon>
                  </Link>
                </Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={1} sm={2} md={3}>
            <Typography variant="h6" gutterBottom>
              CONTACT US
            </Typography>
            <Typography variant="body2">
              <Link href="" color="inherit" underline="hover">
                help@okayartist.com
              </Link>
            </Typography>
            {/* <Typography variant="body2">
              <Link href="/privacy-policy" color="inherit" underline="hover">
                Privacy Policy
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="/refund-policy" color="inherit" underline="hover">
                Refund Policy
              </Link>
            </Typography> */}
          </Grid>

          {/* You can add more columns here if needed */}
        </Grid>
        <Box mt={3}>
          <Typography variant="body2" align="right">
            © {new Date().getFullYear()} Okay Artist. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
