// PaymentComponent.js
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";
import api from "./axiosConfig";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearBasket } from "./redux-slices/BasketSlice";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

function PaymentComponent({ clientSecret }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  // const address = useSelector((state) => state.address.address);
  const items = useSelector((state) => state.basket.items);
  const totalOrderCost = useSelector((state) => state.basket.orderTotalCost);
  const shippingTotal = useSelector((state) => state.basket.shippingTotal);
  const itemsTotalCost = useSelector((state) => state.basket.itemsTotalCost);
  // console.log("BREAKPOINT" + JSON.stringify(address));

  // State to hold the address information
  const [shippingDetails, setShippingDetails] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    email: "",
  });

  const [isAddressComplete, setIsAddressComplete] = useState(false);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentError, setPaymentError] = useState("");

  const mapAddressToShippingDetails = (data) => {
    const { name, address } = data;

    return {
      name: name || "",
      address: `${address.line1}${address.line2 ? ", " + address.line2 : ""}`,
      city: address.city || "",
      state: address.state || "",
      postalCode: address.postal_code || "",
      country: address.country || "",
      email: "", // Assuming email is captured separately or is not provided in this data
    };
  };

  const handlePaymentElementChange = (event) => {
    setIsPaymentComplete(event.complete);
  };

  const handleAddressChange = (event) => {
    if (event.complete) {
      // const { complete } = event;
      // setIsFormComplete(
      //   // complete && elements.getElement(PaymentElement).isComplete()
      //   event.complete
      // );
      // console.log("BREAKPOINT address" + JSON.stringify(event.value, null, 2));
      const mappedShippingDetails = mapAddressToShippingDetails(event.value);
      // setShippingDetails(event.value);
      setShippingDetails(mappedShippingDetails);
      setIsAddressComplete(true);
      // console.log("Address updated:", event.value);
    } else {
      setIsAddressComplete(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !isAddressComplete || !isPaymentComplete) {
      return;
    }

    setIsSubmitting(true);

    const order = {
      shippingDetails,
      totalOrderCost,
      shippingTotal,
      itemsTotalCost,
      items,
    };

    // console.log("BREAKPOINT order= " + JSON.stringify(order, null, 2));

    // try {
    //   const response = await api.post("/api/create-order/", order);
    //   // console.log("Order created successfully:", response.data);

    //   // setOrderCreated(true);
    //   dispatch(clearBasket());
    // } catch (error) {
    //   // console.error("Error creating order:", error);
    // } finally {
    //   setIsSubmitting(false);
    //   // setLoading(false); // Set loading to false after the response is received
    // }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // billing_details: {
        //   // name: address.name,
        //   // email: address.email,
        // },
        // Provide a return_url if your backend is set up to redirect after payment
        return_url: "https://your-website.com/order-confirmation",
      },
      redirect: "if_required", // Ensures no redirection unless required
    });

    if (error) {
      console.log("[error]", error);
      setPaymentError(error.message);
      setIsSubmitting(false);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      try {
        const response = await api.post("/api/create-order/", order);
        // console.log("Order created successfully:", response.data);

        // setOrderCreated(true);
        dispatch(clearBasket());
      } catch (error) {
        // console.error("Error creating order:", error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
        // setLoading(false); // Set loading to false after the response is received
      }
      // console.log("Payment succeeded!");
      navigate("/order-confirmation");
      // Optionally, update the UI or send a confirmation message to the user
    } else if (
      paymentIntent &&
      paymentIntent.status === "requires_payment_method"
    ) {
      // console.log("Payment failed: Payment method required.");
    }
  };

  return (
    <div>
      <form>
        {/* AddressElement to collect address information */}
        <AddressElement
          options={{ mode: "billing" }}
          onChange={handleAddressChange}
        />
        <PaymentElement onChange={handlePaymentElementChange} />
        <Button
          onClick={handleSubmit}
          type="submit"
          disabled={
            !stripe ||
            !clientSecret ||
            !isAddressComplete ||
            !isPaymentComplete ||
            isSubmitting
          }
          variant="contained"
          sx={{ mt: 1 }}
        >
          {isSubmitting ? "Processing..." : "Pay Now"}
        </Button>
      </form>
      <Typography variant="caption" sx={{ color: "red", mt: "2%" }}>
        {paymentError}
      </Typography>
    </div>
  );
}

export default function App() {
  const [clientSecret, setClientSecret] = useState("");
  const totalOrderCost = useSelector((state) => state.basket.orderTotalCost);

  useEffect(() => {
    // Fetch client secret from backend when component mounts
    const fetchClientSecret = async () => {
      try {
        const response = await api.post("create-payment-intent/", {
          amount: 100 * totalOrderCost, // Amount in cents (e.g., $10.00)
        });
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        // console.error("Error fetching client secret:", error);
      }
    };

    fetchClientSecret();
  }, []);

  return (
    clientSecret && (
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <PaymentComponent clientSecret={clientSecret} />
      </Elements>
    )
  );
}
