import { createSlice } from "@reduxjs/toolkit";

export const textSlice = createSlice({
  name: "textState",
  initialState: {
    text: false,
  },
  reducers: {
    setText: (state) => {
      state.text = !state.text;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setText } = textSlice.actions;

export default textSlice.reducer;
