import axios from "axios";
const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchProductDetails = async (listingId) => {
  try {
    const response = await axios.get(`${baseURL}/api/products/${listingId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching product details:", error);
    throw error;
  }
};

export const fetchProduct = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/products/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const fetchFeaturedProduct = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/products-featured/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const fetchFeaturedProductsList = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/products-featured-list/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const fetchFeaturedProductSingle = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/featured-product/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const fetchCatalogProductsList = async (productType) => {
  try {
    const response = await axios.get(
      `${baseURL}/api/product-catalog-list/${productType}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const fetchProductImages = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/product-images/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const fetchProductImagesDetails = async (listingId) => {
  try {
    const response = await axios.get(
      `${baseURL}/api/product-images/${listingId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching product details:", error);
    throw error;
  }
};

export const fetchCollectionList = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/collection-list/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching collection details:", error);
    throw error;
  }
};

// export default fetchProductDetails;
