import React from "react";
import { Typography, Box } from "@mui/material";

const Favorites = () => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Favorites
      </Typography>
      <Typography variant="body1">Here are your favorite items...</Typography>
    </Box>
  );
};

export default Favorites;
