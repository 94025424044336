import { configureStore } from "@reduxjs/toolkit";
import canvasMenuSlice from "./components/redux-slices/CanvasMenuSlice";
import imageReducer from "./components/redux-slices/ImageSlice";
import textReducer from "./components/redux-slices/TextSlice";
import canvasRefReducer from "./components/redux-slices/CanvasRefSlice";
import backMenuReducer from "./components/redux-slices/BackMenuSlice";
import gridMenuClickedReducer from "./components/redux-slices/GridMenuClickedSlice";
import productTypeReducer from "./components/redux-slices/TemplateSlice";
import basketReducer from "./components/redux-slices/BasketSlice";
import productReducer from "./components/redux-slices/ProductSlice";
import addressSlice from "./components/redux-slices/AddressSlice";
export default configureStore({
  reducer: {
    selectedMenu: canvasMenuSlice,
    image: imageReducer,
    textState: textReducer,
    canvasRef: canvasRefReducer,
    backMenu: backMenuReducer,
    gridMenuClicked: gridMenuClickedReducer,
    templates: productTypeReducer,
    basket: basketReducer,
    product: productReducer,
    address: addressSlice,
  },
});
