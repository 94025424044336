import React, { useRef, useEffect, useContext, useState } from "react";
import Box from "@mui/material/Box";
import { fabric } from "fabric";

import { useDispatch, useSelector } from "react-redux";
import { CanvasContext } from "../CanvasContext";
import { setDesignFileURL, setImgMockupURL } from "../redux-slices/ImageSlice";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import { imagesGildan18000, imagesGildan18000Fabric } from "./images";

import imageMockupDarkHeather from "../../assets/mockups/bella-canvas-3001-dark-heather.png";
import imageMockupBlack from "../../assets/mockups/bella-canvas-3001-black.png";
import imageMockupWhite from "../../assets/mockups/bella-canvas-3001-white.png";
import imageMockupRed from "../../assets/mockups/bella-canvas-3001-red.png";
import imageMockupLightBlue from "../../assets/mockups/bella-canvas-3001-light-blue.png";
import imageMockupPink from "../../assets/mockups/bella-canvas-3001-pink.png";

import shirtColorBlack from "../../assets/mockups/fabric-black.png";
import shirtColorWhite from "../../assets/mockups/fabric-white.png";
import shirtColorRed from "../../assets/mockups/fabric-red.png";
import shirtColorPink from "../../assets/mockups/fabric-pink.png";
import shirtColorLightBlue from "../../assets/mockups/fabric-light-blue.png";
import shirtColorDarkHeather from "../../assets/mockups/fabric-dark-heather.png";
import { Tooltip } from "@mui/material";

import { setBasketProduct } from "../redux-slices/ProductSlice";

const ProductMockup = (props) => {
  const productType = useSelector((state) => state.templates.productType);
  //   const productColor = props.imagesFabric;
  //   const mockupColor = props.imagesMockup;

  const [colorPicker, setColorPicker] = useState(props.imagesProduct[0].title);
  const [shirtColorImg, setShirtColorImg] = useState("");
  const [activeColor, setActiveColor] = useState(props.imagesProduct[0].title);

  useEffect(() => {
    // console.log("BREAKPOINT imagesMockup type= " + typeof props.imagesProduct);
    console.log("BREAKPOINT colorpicker = " + colorPicker);
    console.log("BREAKPOINT productType = " + productType);

    const shirt = props.imagesProduct.find(
      (shirt) => shirt.title === colorPicker
    );
    if (shirt) {
      setShirtColorImg(shirt.img);
    } else {
      console.log("No shirt found for the specified color and product type");
      setShirtColorImg(null); // Optionally clear the image or set a default
    }
    // document.getElementById(colorPicker).style.opacity = "70%";
  }, [colorPicker, productType, props.imagesProduct]);

  const dispatch = useDispatch();
  const { canvasRefTemplate } = useContext(CanvasContext);
  console.log("BREAKPOINT inside shirt mockup BEGINNING");
  const canvasRef = useRef(null);
  const designFileURL = useSelector((state) => state.image.designFileURL); // Access the image URL from the Redux stores
  // console.log("BREAKINGPOINT imgTemplateRL= " + designFileURL);

  function downloadCanvasAsImage(fabricCanvas) {
    // Create a temporary canvas
    const tempCanvas = new fabric.StaticCanvas(null, {
      width: 4500,
      height: 5400,
      enableRetinaScaling: false,
    });

    const clonePromises = fabricCanvas.getObjects().map((obj) => {
      return new Promise((resolve) => {
        obj.clone((clone) => {
          // tempCanvas.add(clone);
          resolve(clone); // Resolve the promise with the clone
        });
      });
    });

    Promise.all(clonePromises).then((clonedObjects) => {
      // Instead of adding each clone in the clone callback,
      // we add them here in the correct order.
      clonedObjects.forEach((clone) => {
        tempCanvas.add(clone); // Add each clone to the tempCanvas
      });

      tempCanvas.renderAll(); // Render the tempCanvas after all clones are added
      // Further logic here, e.g., exporting the tempCanvas as an image
    });

    // Wait for all objects to clone before proceeding
    tempCanvas.renderAll();
    setTimeout(() => {
      // Export the temporary canvas to an image
      const dataURL = tempCanvas.toDataURL({
        format: "png",
        multiplier: 1, // Ensure it's exported at the canvas's original size
      });
      dispatch(setDesignFileURL(dataURL));
      // Create a link to download the image
      // const link = document.createElement("a");
      // link.download = "design.png";
      // link.href = dataURL;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);

      // Clean up the temporary canvas
      tempCanvas.dispose();
    }, 500); // Adjust timeout as necessary to ensure canvas has finished rendering
  }
  //
  useEffect(() => {
    downloadCanvasAsImage(canvasRefTemplate);
  }, []);

  const updateProduct = (name, value) => {
    dispatch(setBasketProduct({ name, value }));
  };

  const buttons = props.imagesFabric.map((color) => (
    <Tooltip title={color.title} placement="top" arrow disableInteractive>
      <IconButton
        id={color.title}
        color="success"
        onClick={() => {
          setColorPicker(color.title);
          setActiveColor(color.title);
          updateProduct("color", color.title);
        }}
        sx={{
          opacity: activeColor === color.title ? 0.7 : 1.0, // Change opacity based on active state
          border: activeColor === color.title ? "2px solid #87CEEB" : "none", // Green border when active
          m: 1, // Margin around buttons for better visibility
        }}
      >
        <Avatar
          key={`${color.title} shirt`}
          alt={`${color.title} shirt`}
          src={color.img}
        />
      </IconButton>
    </Tooltip>
  ));

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted
    if (isMounted) {
      //save canvas to redux
      // downloadCanvasAsImage(canvasRefTemplate);

      // Initialize Fabric.js canvas
      const fabricCanvas = new fabric.Canvas(canvasRef.current, {
        // width: window.innerWidth * 0.6,
        // height: window.innerHeight * 0.6,
        width: 600,
        height: 600,
        // backgroundColor: "green",
        selection: false, // Disables group selection
        preserveObjectStacking: true,
        interactive: false, // Ensure interactivity is enabled
      });

      canvasRef.current.fabric = fabricCanvas; // Store the Fabric.js canvas instance

      const resizeCanvas = () => {
        fabricCanvas.setDimensions({
          width: window.innerWidth * 0.6,
          height: window.innerHeight * 0.6,
        });
      };

      window.addEventListener("resize", resizeCanvas);
      // Load and add image to the canvas

      function loadImage(url) {
        return new Promise((resolve, reject) => {
          fabric.Image.fromURL(
            url,
            (img) => {
              resolve(img);
            },
            {
              // In case of error you can reject the promise
              onError: (err) => reject(err),
            }
          );
        });
      }
      Promise.all([loadImage(shirtColorImg), loadImage(designFileURL)])
        .then(([img, imgDesign]) => {
          const canvasAspectRatio = fabricCanvas.width / fabricCanvas.height;
          const imgAspectRatio = img.width / img.height;
          const imgDesignAspectRation = imgDesign.width / imgDesign.height;
          let scale, scaleDesign;

          if (imgAspectRatio > canvasAspectRatio) {
            // If image aspect ratio is greater than canvas's, fit image by width
            scale = fabricCanvas.width / img.width;
          } else {
            // Else, fit image by height
            scale = fabricCanvas.height / img.height;
          }
          //scale of the design file
          if (imgDesignAspectRation > imgAspectRatio) {
            // If image aspect ratio is greater than canvas's, fit image by width
            scaleDesign = fabricCanvas.width / imgDesign.width;
          } else {
            // Else, fit image by height
            scaleDesign = fabricCanvas.height / imgDesign.height;
          }
          //   setScaleGlobal(scale);
          // Setting the scale and centering the image on the canvas
          img.set({
            scaleX: scale,
            scaleY: scale,
            left: 0,
            top: 0,
            originX: "left", // Set origin to the top-left corner
            originY: "top", // Set origin to the top-left corner
            myCustomId: "image",
            selectable: false, // Keep the image selectable, or set to false to prevent selection
            hasControls: false, // Hide controls, prevent resizing
            hasBorders: false, // Hide borders
            lockMovementX: true, // Prevent moving on the x-axis
            lockMovementY: true, // Prevent moving on the y-axis
            lockRotation: true, // Prevent rotation
            lockScalingX: true, // Prevent scaling on the x-axis
            lockScalingY: true, // Prevent scaling on the y-axis
            lockSkewingX: true, // Prevent skewing on the x-axis
            lockSkewingY: true, // Prevent skewing on the y-axis
          });
          fabricCanvas.add(img);
          fabricCanvas.sendToBack(img);
          fabricCanvas.setDimensions({
            // scaleX: scale,
            // scaleY: scale,
            width: img.width * scale,
            height: img.height * scale,
            left: 0,
            top: 0,
            originX: "left", // Set origin to the top-left corner
            originY: "top", // Set origin to the top-left corner
            // myCustomId: "image",
          });

          // Create a rectangle with dashed borders
          const dashedRect = new fabric.Rect({
            scaleX: scale,
            scaleY: scale,
            // left: img.getScaledWidth() / 2,
            // top: img.getScaledHeight() / 2,
            fill: "transparent", // Transparent fill
            // width: 4500 * scale,
            // height: 5400 * scale,
            width: img.getScaledWidth(),
            height: img.getScaledHeight(),
            stroke: "white", // Color of the stroke
            strokeWidth: 2, // Width of the stroke
            strokeDashArray: [10, 5], // Dashed lines: 10 pixels filled, 5 pixels empty
            selectable: false,
          });
          dashedRect.set({
            left: img.getScaledWidth() / 2 - dashedRect.getScaledWidth() / 2,
            top: img.getScaledHeight() / 2 - dashedRect.getScaledHeight() / 2,
          });
          // fabricCanvas.add(dashedRect);
          var designScale;
          if (props.productType === "Tank Top") designScale = scaleDesign / 1.4;
          else if (props.productType === "Hoodie")
            designScale = scaleDesign / 1.5;
          else {
            designScale = scaleDesign / 1.2;
          }
          imgDesign.set({
            selectable: false, // Keep the image selectable, or set to false to prevent selection
            hasControls: false, // Hide controls, prevent resizing
            hasBorders: false, // Hide borders
            lockMovementX: true, // Prevent moving on the x-axis
            lockMovementY: true, // Prevent moving on the y-axis
            lockRotation: true, // Prevent rotation
            lockScalingX: true, // Prevent scaling on the x-axis
            lockScalingY: true, // Prevent scaling on the y-axis
            lockSkewingX: true, // Prevent skewing on the x-axis
            lockSkewingY: true, // Prevent skewing on the y-axis
            // backgroundColor: "blue",
            scaleX: designScale,
            scaleY: designScale,

            // left: 0,
            // top: 0,
            originX: "left", // Set origin to the top-left corner
            originY: "top",
            // width: dashedRect.getScaledWidth(),
            // height: dashedRect.getScaledHeight(),
          });

          var designTop;
          if (props.productType === "Tank Top")
            designTop =
              img.getScaledHeight() / 2 - imgDesign.getScaledHeight() / 3.1;
          else {
            designTop =
              img.getScaledHeight() / 2 - imgDesign.getScaledHeight() / 2.5;
          }
          imgDesign.set({
            left: img.getScaledWidth() / 2 - imgDesign.getScaledWidth() / 2,
            top: designTop,
          });
          // imgDesign.clipPath = dashedRect;
          fabricCanvas.add(imgDesign);

          fabricCanvas.renderAll();
          const multiplier = window.devicePixelRatio;

          const imgMockupURL = fabricCanvas.toDataURL({
            format: "jpg",
            multiplier: multiplier, // Ensure it's exported at the canvas's original size
          });
          dispatch(setImgMockupURL(imgMockupURL));
        })
        .catch((error) => {});

      console.log("BREAKPOINT inside shirt mockup before return");
      return () => {
        console.log(
          "BREAKPOINT inside shirt mockup useEffect return beginning"
        );
        isMounted = false;
        window.removeEventListener("resize", resizeCanvas);

        if (
          fabricCanvas &&
          typeof fabricCanvas.dispose &&
          !fabricCanvas.isDisposed === "function"
        ) {
          try {
            fabricCanvas.dispose();
            dispatch(setDesignFileURL(""));
            fabricCanvas.isDisposed = true; // Custom flag to prevent multiple disposals
            // canvasRef.current = null;
            console.log(
              "BREAKPOINT inside shirt mockup useEffect after dispose"
            );
          } catch (error) {
            console.error("Error disposing fabric canvas:", error);
          }
        }
      };
    }
  }, [designFileURL, shirtColorImg]);
  return (
    <Box>
      <Stack
        direction={"column"}
        spacing={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor: "red",
        }}
      >
        <canvas
          // style={{ backgroundColor: "blue" }}
          // style={{ backgroundColor: "blue", zIndex: +2 }}
          id="mockupCanvas"
          ref={canvasRef}
        ></canvas>
        <ButtonGroup
          orientation="horizontal"
          aria-label="horizontal button group"
          variant="text"
        >
          {buttons}
        </ButtonGroup>
      </Stack>
    </Box>
  );
};

export default ProductMockup;
