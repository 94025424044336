// useCanvasDownloader.js
import { useCallback, useContext, useEffect } from "react";
import { fabric } from "fabric";
import { CanvasContext } from "./CanvasContext";

export const useCanvasDownloader = () => {
  // canvasInstance.setZoom(1);
  const { scaleGlobal, canvasRefTemplate, saveClick, setSaveClick } =
    useContext(CanvasContext);

  //download the canvas content
  useEffect(() => {
    if (canvasRefTemplate && saveClick) {
      function downloadCanvasAsImage(fabricCanvas) {
        // Create a temporary canvas
        const tempCanvas = new fabric.StaticCanvas(null, {
          width: 4500,
          height: 5400,
          enableRetinaScaling: false,
        });

        // // Clone the objects to the temporary canvas
        // fabricCanvas.getObjects().forEach((obj) => {
        //   obj.clone((clone) => {
        //     tempCanvas.add(clone);
        //     tempCanvas.renderAll();
        //   });
        // });
        const clonePromises = fabricCanvas.getObjects().map((obj) => {
          return new Promise((resolve) => {
            obj.clone((clone) => {
              // tempCanvas.add(clone);
              resolve(clone); // Resolve the promise with the clone
            });
          });
        });

        Promise.all(clonePromises).then((clonedObjects) => {
          // Instead of adding each clone in the clone callback,
          // we add them here in the correct order.
          clonedObjects.forEach((clone) => {
            tempCanvas.add(clone); // Add each clone to the tempCanvas
          });

          tempCanvas.renderAll(); // Render the tempCanvas after all clones are added
          // Further logic here, e.g., exporting the tempCanvas as an image
        });

        // Wait for all objects to clone before proceeding
        tempCanvas.renderAll();
        setTimeout(() => {
          // Export the temporary canvas to an image
          const dataURL = tempCanvas.toDataURL({
            format: "png",
            multiplier: 1, // Ensure it's exported at the canvas's original size
          });

          // Create a link to download the image
          const link = document.createElement("a");
          link.download = "design.png";
          link.href = dataURL;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Clean up the temporary canvas
          tempCanvas.dispose();
        }, 500); // Adjust timeout as necessary to ensure canvas has finished rendering
      }
      downloadCanvasAsImage(canvasRefTemplate);
    } else {
      // console.log("canvasRefTemplate not found");
    }
    setSaveClick(false);
  }, [saveClick]);

  const downloadImage = useCallback((format = "png") => {
    if (!canvasRefTemplate) {
      // console.error("Canvas instance is not provided");
      return;
    }
    let dataUrl;

    // Check the format and set the data URL accordingly
    if (format === "png") {
      dataUrl = canvasRefTemplate.toDataURL({
        format: "png",
        multiplier: 1, // Doubling the image size
      });
      // console.log("BREAKPOINT --------------" + dataUrl);
    } else if (format === "jpeg") {
      dataUrl = canvasRefTemplate.toDataURL({
        format: "png",
        multiplier: 1, // Doubling the image size
      });
    } else {
      // console.error("Unsupported format");
      return;
    }

    // Create a temporary anchor element and trigger the download
    // const downloadLink = document.createElement("a");
    // downloadLink.href = dataUrl;
    // downloadLink.download = `canvas-image.${format}`;
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
  }, []);

  return downloadImage;
};

// //downloader for product design page
// export const useDesignCanvasDownloader = () => {
//   const { productCanvasRef, setSaveClickProduct, saveClickProduct } =
//     useContext(CanvasContext);

//   //download the canvas content
//   useEffect(() => {
//     if (productCanvasRef && saveClickProduct) {
//       function downloadCanvasAsImage(fabricCanvas) {
//         // Create a temporary canvas
//         const tempCanvas = new fabric.StaticCanvas(null, {
//           width: 4500,
//           height: 5100,
//           enableRetinaScaling: false,
//         });
//         const clonePromises = fabricCanvas.getObjects().map((obj) => {
//           return new Promise((resolve) => {
//             obj.clone((clone) => {
//               // tempCanvas.add(clone);
//               resolve(clone); // Resolve the promise with the clone
//             });
//           });
//         });

//         Promise.all(clonePromises).then((clonedObjects) => {
//           // Instead of adding each clone in the clone callback,
//           // we add them here in the correct order.
//           clonedObjects.forEach((clone) => {
//             tempCanvas.add(clone); // Add each clone to the tempCanvas
//           });

//           tempCanvas.renderAll(); // Render the tempCanvas after all clones are added
//           // Further logic here, e.g., exporting the tempCanvas as an image
//         });

//         // Wait for all objects to clone before proceeding
//         tempCanvas.renderAll();
//         setTimeout(() => {
//           // Export the temporary canvas to an image
//           const dataURL = tempCanvas.toDataURL({
//             format: "png",
//             multiplier: 1, // Ensure it's exported at the canvas's original size
//           });

//           // Create a link to download the image
//           const link = document.createElement("a");
//           link.download = "design.png";
//           link.href = dataURL;
//           document.body.appendChild(link);
//           link.click();
//           document.body.removeChild(link);

//           // Clean up the temporary canvas
//           tempCanvas.dispose();
//         }, 500); // Adjust timeout as necessary to ensure canvas has finished rendering
//       }
//       downloadCanvasAsImage(productCanvasRef);
//     } else {
//       console.log("productCanvasRef not found");
//     }
//     setSaveClickProduct(false);
//   }, [saveClickProduct]);

//   // const downloadImage = useCallback((format = "png") => {
//   //   if (!productCanvasRef) {
//   //     console.error("Canvas instance is not provided");
//   //     return;
//   //   }
//   //   let dataUrl;

//   //   // Check the format and set the data URL accordingly
//   //   if (format === "png") {
//   //     dataUrl = productCanvasRef.toDataURL({
//   //       format: "png",
//   //       multiplier: 1, // Doubling the image size
//   //     });
//   //   } else if (format === "jpeg") {
//   //     dataUrl = productCanvasRef.toDataURL({
//   //       format: "png",
//   //       multiplier: 1, // Doubling the image size
//   //     });
//   //   } else {
//   //     console.error("Unsupported format");
//   //     return;
//   //   }
//   // }, []);

//   // return downloadImage;
// };

// export default useCanvasDownloader;

export const useImageDownloader = () => {
  const downloadImage = useCallback((imageUrl, format = "png") => {
    if (!imageUrl) {
      // console.error("Image URL is not provided");
      return;
    }
    // Assuming the format is a part of the imageUrl or irrelevant
    // since the image is already in a specific format.

    // Create a temporary anchor element and trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = imageUrl;
    // You might want to extract the filename or provide a meaningful default
    const filename =
      imageUrl.split("/").pop().split("?")[0] || `downloaded-image.${format}`;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    // console.log("Image download part is finished");
  }, []);

  return downloadImage;
};
