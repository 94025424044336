import React, { useRef } from "react";
import Draggable from "react-draggable";
import { AppBar, Toolbar, IconButton, Stack } from "@mui/material";
import { Brush, Crop, Palette } from "@mui/icons-material";
import Box from "@mui/material/Box";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  setImageURL,
  setOriginalImageUR,
  setImgTemplateURL,
} from "./redux-slices/ImageSlice";
import Tooltip from "@mui/material/Tooltip";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import { backgroundRemoverREMBG } from "./image-processing-tools";
import TextEditingInterface from "./TextEditingInterface";
import TitleOutlinedIcon from "@mui/icons-material/TitleOutlined";
import TypographyPopover from "./TypographyPopover";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ImageFiltersTemplate from "./Design Templates/ImageFiltersTemplate";
import PreviewIcon from "@mui/icons-material/Preview";
import ViewMockup from "./Design Templates/ViewMockup";

const FloatingToolbar = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const imgTemplateURL = useSelector((state) => state.image.imgTemplateURL);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Handle the file upload
      const url = URL.createObjectURL(file);
      dispatch(setImgTemplateURL(url)); // Dispatch action to save URL
      //   dispatch(setOriginalImageURL(url));

      // Create an Image object
    }
  };

  const handleRemoveBackground = () => {
    backgroundRemoverREMBG(imgTemplateURL)
      .then((url) => {
        dispatch(setImgTemplateURL(url));
      })
      .catch((error) => {
        console.log("Error processing the image: " + error);
      });
  };
  return (
    // <Draggable bounds="parent" handle=".handle">
    <Box
      sx={{
        // width: "20%",
        // position: "sticky",
        // height: "5vh",
        borderRadius: 0,
        zIndex: 1201,
        // alignItems: "center",
        // backgroundColor: "red",
        marginBottom: "2%",
        // bottom: 100,
        // top: "auto",
      }}
    >
      <Stack direction="column" spacing={1}>
        {/* Tool Icons */}
        <Tooltip
          title="Image upload"
          placement="bottom"
          arrow
          disableInteractive
        >
          <IconButton
            onClick={() => {
              fileInputRef.current.click();
            }}
            color="primary"
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="image/*"
            />
            <PhotoOutlinedIcon />
          </IconButton>
        </Tooltip>

        <Tooltip
          title="Remove Background"
          placement="bottom"
          arrow
          disableInteractive
        >
          <IconButton onClick={handleRemoveBackground} color="primary">
            <WallpaperIcon />
          </IconButton>
        </Tooltip>
        <ImageFiltersTemplate />
        {/* <Tooltip title="Edit Text" placement="bottom" arrow disableInteractive>
          <IconButton color="primary">
            <TitleOutlinedIcon />
          </IconButton>
        </Tooltip> */}
        <TypographyPopover />

        <ViewMockup />
        {/* <IconButton color="primary">
          <Crop />
        </IconButton>
        <IconButton color="primary">
          <Palette />
        </IconButton> */}
      </Stack>
    </Box>
    // </Draggable>
  );
};

export default FloatingToolbar;
