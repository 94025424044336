import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import theme from "../theme";
// import productsData from "./data/productsData"; // Assume this is your data source
import { fetchCatalogProductsList } from "./fetchProductDetails";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const CatalogListPage = () => {
  const { productType } = useParams();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  // useEffect(() => {
  //   const filtered = productsData.filter((product) =>
  //     product.name.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   setFilteredProducts(filtered);
  // }, [searchTerm]);

  //fetch dynamodb for product
  useEffect(() => {
    const getProducts = async () => {
      try {
        const data = await fetchCatalogProductsList(productType);
        setFilteredProducts(data);
      } catch (error) {
        // setError(error);
      } finally {
        // setLoading(false);
      }
    };

    getProducts();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        {/* Dynamic Meta Tags */}
        <Helmet>
          <title>{`${productType} - Catalog | Okay Artist`}</title>
          <meta
            name="description"
            content={`Explore our selection of ${productType} from the Okay Artist catalog. Find the perfect item for yourself or as a gift.`}
          />
          <meta
            name="keywords"
            content={`${productType}, custom ${productType}, okay artist, catalog, shop ${productType}`}
          />
          <meta
            property="og:title"
            content={`${productType} - Catalog | Okay Artist`}
          />
          <meta
            property="og:description"
            content={`Browse a wide range of custom ${productType} at Okay Artist.`}
          />
          <meta
            property="og:url"
            content={`https://www.okayartist.com/catalog/${productType}`}
          />
          <meta
            property="og:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`${productType} - Catalog | Okay Artist`}
          />
          <meta
            name="twitter:description"
            content={`Discover custom ${productType} in the Okay Artist catalog.`}
          />
          <meta
            name="twitter:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
        </Helmet>
        <Header />
        <Container sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
          <Box sx={{ mb: 4 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search for products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Grid container spacing={4} columns={{ xs: 4, sm: 8, md: 12 }}>
            {filteredProducts.map((product) => (
              <Grid item key={product.id} xs={4} sm={4} md={4}>
                <Card
                  raised
                  // component={Link}
                  onClick={() => {
                    navigate(
                      `/products/${product.listingId}/${product.designId}`
                    );
                  }}
                  sx={{
                    textDecoration: "none",
                    borderRadius: "8px",
                    boxShadow: 3,
                    cursor: "pointer",
                    overflow: "hidden",
                    "&:hover": {
                      boxShadow: 6,
                      transform: "scale(1.05)",
                      transition:
                        "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    },
                    "&:hover img": {
                      opacity: 0.8,
                    },
                  }}
                >
                  <CardActionArea>
                    {product.images && product.images.length > 0 && (
                      <CardMedia
                        component="img"
                        // height="200"
                        sx={{
                          borderRadius: "8px 8px 0 0",
                          transition: "opacity 0.3s ease-in-out",
                        }}
                        image={product.images[0].img}
                        alt={product.name}
                      />
                    )}
                    <CardContent>
                      <Typography variant="h6">{product.title}</Typography>
                      {/* <Typography variant="body2" color="text.secondary">
                      {product.description}
                    </Typography> */}
                      <Typography variant="body1" color="primary">
                        ${product.sizes[0].price}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default CatalogListPage;
