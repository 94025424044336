import { createSlice } from "@reduxjs/toolkit";

export const TemplateSlice = createSlice({
  name: "templates",
  initialState: {
    productType: "T-shirt",
  },
  reducers: {
    setProductType: (state, action) => {
      state.productType = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProductType } = TemplateSlice.actions;

export default TemplateSlice.reducer;
