import React, { useState, useEffect } from "react";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { ThemeProvider } from "@mui/material/styles";
// import theme from "../theme";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
// import { setImageURL } from "./redux-slices/ImageSlice";
// import PopoverMenu from "./PopoverMenu";
import ImageResizePanel from "./ImageResizePanel";
// import { setBackMenu } from "./redux-slices/BackMenuSlice";
import { setGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";

import ImageCropPanel from "./ImageCropPanel";
import TemplateList from "./TemplateList";
import ImageRotatePanel from "./ImageRotatePanel";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid"; // Grid version 1
import ImageGrayscalePanel from "./ImageGrayscalePanel";
import ImageEnhancePanel from "./ImageEnhancePanel";
import ImageColorPanel from "./ImageColorPanel";
import ImageExposurePanel from "./ImageExposurePanel";
import ImageFiltersGeneralPanel from "./ImageFiltersGeneralPanel";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import zIndex from "@mui/material/styles/zIndex";
import {
  // oilPainting,
  // sketchStyle,
  // cartoonizerEffect,
  // watercolorEffect,
  // enhanceImage,
  // resizeImage,
  // removeBackgroundAI,
  // styleTransfer,
  // lineArt,
  // removeBackgroundAI2,
  // cartoonizerEffect2,
  // styleTransfer2,
  cartoonizerEffect2,
  convertToSketchOpencv,
  imageToVectorBlackAndWhite,
  imageToVectorBlackAndWhite2,
  backgroundRemoverREMBG,
  convertToSketch2,
  backgroundRemoverREMBGGoogle,
} from "./image-processing-tools";

const shirtTemplate = [{ style: "Shirt Template 1", handleFunction: "" }];

const CanvasMenuGridList = (props) => {
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const [processedImageUrl, setProcessedImageUrl] = useState("");
  const imageURL = useSelector((state) => state.image.imageURL); // Access the image URL from the Redux store
  // const backMenu = useSelector((state) => state.backMenu.backMenu); // Access the image URL from the Redux store
  const gridMenuClicked = useSelector(
    (state) => state.gridMenuClicked.gridMenuClicked
  ); // Access the image URL from the Redux store
  // console.log("imageURL in Menu Grid=" + imageURL);
  const dispatch = useDispatch();
  const theme = useTheme();

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const handleClick = (event) => {};

  // const handleClose = () => {
  //   setAnchorEl(null);
  // console.log("BREAKPOINT--------????--------");
  // const [menuClicked, setMenuClicked] = useState(false);
  const [menuStyle, setMenuStyle] = useState("");
  let propertiesPanel;
  if (menuStyle === "Image Resize") {
    propertiesPanel = <ImageResizePanel imageURL={imageURL} />;
  } else if (menuStyle === "Crop") {
    propertiesPanel = <ImageCropPanel imageURL={imageURL} />;
  } else if (menuStyle === "Rotate") {
    propertiesPanel = <ImageRotatePanel imageURL={imageURL} />;
  } else if (menuStyle === "Grayscale") {
    propertiesPanel = <ImageGrayscalePanel imageURL={imageURL} />;
  } else if (menuStyle === "Image Enhancement") {
    propertiesPanel = <ImageEnhancePanel imageURL={imageURL} />;
  } else if (menuStyle === "Hue & Saturation") {
    propertiesPanel = <ImageColorPanel imageURL={imageURL} />;
  } else if (menuStyle === "Exposure") {
    propertiesPanel = <ImageExposurePanel imageURL={imageURL} />;
  } else if (menuStyle === "Vectorize Black & White") {
    propertiesPanel = (
      <ImageFiltersGeneralPanel
        imageURL={imageURL}
        handleFunction={imageToVectorBlackAndWhite}
      />
    );
  } else if (menuStyle === "Background Remover") {
    propertiesPanel = (
      <ImageFiltersGeneralPanel
        imageURL={imageURL}
        handleFunction={backgroundRemoverREMBGGoogle}
      />
    );
  } else if (menuStyle === "Sketch") {
    propertiesPanel = (
      <ImageFiltersGeneralPanel
        imageURL={imageURL}
        handleFunction={convertToSketchOpencv}
      />
    );
  } else if (menuStyle === "Sketch 2") {
    propertiesPanel = (
      <ImageFiltersGeneralPanel
        imageURL={imageURL}
        handleFunction={convertToSketch2}
      />
    );
  } else if (menuStyle === "Cartoonizer 2") {
    propertiesPanel = (
      <ImageFiltersGeneralPanel
        imageURL={imageURL}
        handleFunction={cartoonizerEffect2}
      />
    );
  } else if (menuStyle === "Vectorize Black & White 2") {
    propertiesPanel = (
      <ImageFiltersGeneralPanel
        imageURL={imageURL}
        handleFunction={imageToVectorBlackAndWhite2}
      />
    );
  } else if (menuStyle === "Vectorize Black & White 2") {
    propertiesPanel = (
      <ImageFiltersGeneralPanel
        imageURL={imageURL}
        handleFunction={imageToVectorBlackAndWhite2}
      />
    );
  }

  // };
  // useEffect(() => {
  //   dispatch(setImageURL(imageURL));
  // }, [gridMenuClicked]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ position: "relative" }}>
        {/* image tools panel */}
        {gridMenuClicked && !matchesXS && (
          <Box sx={{ justifyContent: "center", m: 1 }}>{propertiesPanel}</Box>
        )}
        {gridMenuClicked && matchesXS && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: "-50vh",
              bottom: 0,
              // justifyContent: "center",
              // m: 1,
              borderStyle: "outset",
              backgroundColor: "#777777",
              transform: "scale(0.6)", // Adjust the scale value as needed
              transformOrigin: "center bottom", // Adjust the origin of the transformation
            }}
          >
            {propertiesPanel}
          </Box>
        )}
        {/* for displaying menus for both dimensions */}
        {!gridMenuClicked && (
          <Box sx={{ overflow: "auto" }}>
            <Stack
              direction={!matchesXS ? "column" : "row"}
              sx={{
                // mr: "5%",
                // justifyContent: "center",
                "&>*": { mr: 2 },
                // nowrap: true, // Prevent buttons from wrapping
                // width: { xs: 400 },
              }}
              divider={
                matchesXS ? (
                  <Divider orientation="vertical" flexItem />
                ) : (
                  <Divider orientation="horizontal" flexItem />
                )
              }
              useFlexGap
              // alignItems={{ xs: "flex-start", md: "stretch" }}
              // justifyContent={{ xs: "flex-start", md: "stretch" }}
              // sx={{
              //   width: { xs: ".5 rem", sm: 100, md: 100, lg: 100, xl: 100 },
              // }}
              dense
            >
              {props.menu.map((value) => {
                return (
                  <Button
                    key={value}
                    disablePadding
                    // alignItems="flex-start
                    onClick={(e) => {
                      // setAnchorEl(e.currentTarget);
                      // setMenuClicked(!menuClicked);
                      dispatch(setGridMenuClicked());
                      setMenuStyle(value.style);
                      // value
                      //   .handleFunction(imageURL)
                      //   .then((url) => {
                      //     // setProcessedImageUrl(url); // Save the processed image URL in state
                      //     dispatch(setImageURL(url));
                      //   })
                      //   .catch((error) => {
                      //     console.error("Error processing image:", error);
                      //   });
                    }}
                    divider
                    // sx={{ overflow: "hidden" }}
                  >
                    <Grid
                      container
                      spacing={2}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      direction={!matchesXS ? "row" : "column"}
                    >
                      <Grid item xs={1} sm={2} md={3}>
                        <Avatar
                          sx={{
                            width: {
                              xs: 20,
                              sm: 20,
                              md: 25,
                              lg: 30,
                              xl: 40,
                            },
                            // paddingRight: "5%",
                            height: "auto",
                          }}
                          alt={`Avatar n°${value}`}
                          src={require(`../assets/menu-images/${value.style}.png`)}
                          variant="rounded"
                        />
                      </Grid>
                      <Grid item xs={3} sm={6} md={9}>
                        <Typography
                          gutterBottom
                          // variant="button"
                          sx={{
                            fontSize: {
                              xs: "0.5rem",
                              sm: "0.75rem",
                              md: ".8rem",
                            },
                            whiteSpace: "normal",
                          }}
                        >{`${value.style}`}</Typography>
                      </Grid>
                    </Grid>
                  </Button>
                );
              })}
            </Stack>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default CanvasMenuGridList;
