import React, { useContext, useEffect, useState, useRef } from "react";
import { fabric } from "fabric";
import imageBG from "../../assets/design-templates/retro-background.png";
import imageMask from "../../assets/design-templates/retro-background-mask.svg";
// import imageMask from "../../assets/design-templates/retro-background-mask-black.svg";
import { useDispatch, useSelector } from "react-redux";
import { CanvasContext } from "../CanvasContext";
import {
  setActiveTemplate,
  setImageURL,
  setImgTemplateURL,
} from "../redux-slices/ImageSlice";

const DesignShirt2 = () => {
  console.log("START OF DESIGN SHIRT 1");
  const [fabricGlobal, setFabricGlobal] = useState(null);
  const textObjectRef = useRef(null);

  const { canvasRef, setCanvasRefTemplate, textDetails } =
    useContext(CanvasContext);
  const dispatch = useDispatch();
  const imgTemplateURL = useSelector((state) => state.image.imgTemplateURL); // Access the image URL from the Redux stores
  const activeTemplate = useSelector((state) => state.image.activeTemplate);

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted
    try {
      function adjustCanvasSize(fabricCanvas) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Calculate the scale factor
        const scaleX = viewportWidth / fabricCanvas.width;
        const scaleY = viewportHeight / fabricCanvas.height;
        const scaleToFit = Math.min(scaleX, scaleY);

        // Set canvas zoom
        fabricCanvas.setZoom(scaleToFit);

        // Update canvas element size to match the scaled drawing area
        fabricCanvas.setWidth(fabricCanvas.width * scaleToFit);
        fabricCanvas.setHeight(fabricCanvas.height * scaleToFit);
      }

      function scaleImage1ToFitImage2(image1, image2) {
        // Calculate the scale factors for width and height
        const scaleX = image2.width / image1.width;
        const scaleY = image2.height / image1.height;

        // Choose the smaller scale factor to ensure image1 fits within image2
        const scaleToFit = Math.min(scaleX, scaleY);

        // Apply the scale to image1
        image1.scale(scaleToFit);

        // Optionally, center image1 within image2
        image1.set({
          left: (image2.width - image1.getScaledWidth()) / 2,
          top: (image2.height - image1.getScaledHeight()) / 2,
        });

        // If using Fabric.js, don't forget to re-render the canvas if necessary
        // fabricCanvas.renderAll();
      }

      dispatch(setActiveTemplate(true));

      const fabricCanvas = new fabric.Canvas(canvasRef.current, {
        width: 4500,
        height: 5400,
        // backgroundColor: "red",
        enableRetinaScaling: false,
        // width: imgWidth,
        // height: imgHeight,

        preserveObjectStacking: true,
        interactive: true, // Ensure interactivity is enabled
        backgroundColor: "rgba(119, 119, 119, 0.6)",
        stroke: "rgba(255,255,255,0.5)",
        strokeWidth: 5,
        shadow: "rgba(0,0,0,0.6) 5px 5px 5px",
      });
      setFabricGlobal(fabricCanvas);

      if (fabricCanvas && fabricCanvas.getContext) {
        // Adjust the canvas size to fit the viewport
        adjustCanvasSize(fabricCanvas);
        const zoom = fabricCanvas.getZoom();

        function loadImage(url) {
          return new Promise((resolve, reject) => {
            fabric.Image.fromURL(
              url,
              (img) => {
                resolve(img);
              },
              {
                // In case of error you can reject the promise
                onError: (err) => reject(err),
              }
            );
          });
        }

        Promise.all([
          loadImage(imageBG),
          loadImage(imgTemplateURL),
          loadImage(imageMask),
        ])
          .then(([img1, img2, imgMask]) => {
            if (isMounted) {
              scaleImage1ToFitImage2(img2, img1);
              fabricCanvas.renderAll();

              // Adjust image settings
              img1.set({
                left:
                  (fabricCanvas.width / zoom - img1.width * img1.scaleX) / 2,
                top: 400,
                angle: 0,
                opacity: 1.0,
              });

              img2.set({
                left:
                  (fabricCanvas.width / zoom - img2.width * img2.scaleX) / 2,
                top: 400,
                angle: 0,
                opacity: 1.0,
              });

              fabric.loadSVGFromURL(imageMask, function (objects, options) {
                var svgObj = fabric.util.groupSVGElements(objects, options);
                svgObj.set({
                  left: 935,
                  // (fabricCanvas.width / zoom - img1.width * img1.scaleX) / 2,
                  top: 485,
                  scaleX: 1,
                  scaleY: 1, // Adjust scale as needed
                  // originX: "left",
                  // originY: "top",
                  absolutePositioned: true,
                });

                img2.clipPath = svgObj;
                // img2.clipPath.originX = "center";
                // img2.clipPath.originY = "center";
                // img2.clipPath.top = -img2.height / 2;
                // img2.clipPath.left = -img2.width / 2;
                fabricCanvas.renderAll();
              });

              fabricCanvas.add(img1);
              fabricCanvas.add(img2);
              // Ensure the clipping updates when the image is moved
              // img2.on("moving", function () {
              //   img2.clipPath.set({
              //     left: img2.left - img1.left + img1.getScaledWidth() / 2,
              //     top: img2.top - img1.top + img1.getScaledHeight() / 2,
              //   });
              //   fabricCanvas.renderAll();
              // });

              // Optionally adjust stack order
              // For example, if you always want img1 beneath img2:
              img1.sendToBack();
              // Or for img2 to be on top, though adding it last already does this:
              // img2.bringToFront();
              // Add the text over the images
              const newText = new fabric.IText("Okay Artis2", {
                fontFamily: "Ayar Juno",
                fill: "white",
                fontSize: 500,
                selectable: true,
                hasControls: true,
                hasBorders: true,
              });

              // Calculate the center position for the text
              // Note: fabric.js may not immediately have width and height metrics for newText; consider setting initial left/top then adjusting
              const centerX =
                (fabricCanvas.width / zoom - newText.width * newText.scaleX) /
                2;
              const centerY =
                (fabricCanvas.height / zoom - newText.height * newText.scaleY) /
                2;
              newText.set({ left: centerX, top: centerY });
              // Add a renderAll call in the callback of the setText to ensure the canvas updates with correct width/height of newText
              newText.on("changed", () => {
                const centerX =
                  (fabricCanvas.width / zoom - newText.width * newText.scaleX) /
                  2;
                const centerY =
                  (fabricCanvas.height / zoom -
                    newText.height * newText.scaleY) /
                  2;
                newText.set({ left: centerX, top: centerY });
                fabricCanvas.renderAll();
              });

              // Now add the text object to the canvas
              fabricCanvas.add(newText);

              // Bring the text to the front if needed
              newText.bringToFront();
              textObjectRef.current = newText;

              fabricCanvas.renderAll();
            }
          })
          .catch((error) => {
            console.error("Error loading images:", error);
          });

        fabricCanvas.renderAll(); // Render the canvas to display the new circle
        //store fabric canvas to state in context for do
        console.log("BREAKPOINT fabricCanvas " + fabricCanvas);
        setCanvasRefTemplate(fabricCanvas);
      }
      return () => {
        isMounted = false; // Indicate the component has unmounted
        dispatch(setActiveTemplate(false));

        if (!isMounted) {
          dispatch(setImageURL("fakeURL to trigger canvas"));
          setCanvasRefTemplate(null);
          fabricCanvas.dispose();
        }
        // window.removeEventListener("resize", adjustCanvasSize(fabricCanvas));
      };
    } catch (error) {
      console.log("----ERROR inside DesignShirt1: " + error);
    }
  }, [imgTemplateURL]);

  useEffect(() => {
    if (textObjectRef.current && textDetails) {
      // Directly update the text object properties based on textDetails
      textObjectRef.current.set({
        fontFamily: textDetails.font,
        // Set other properties based on textDetails as needed
      });
      fabricGlobal.renderAll(); // Re-render canvas to apply the text object updates
    }
  }, [textDetails]); // Depend on textDetails and fabricCanvas
  console.log("END OF DESIGN SHIRT 1");
  return <></>;
};
export default DesignShirt2;
