import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import theme from "../theme";
import { fetchProduct } from "./fetchProductDetails";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

// Animation variants
const variants = {
  hidden: { opacity: 0, y: 50 }, // Start hidden with a vertical offset
  visible: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: index * 0.2, // Add delay based on index to stagger the cards
      duration: 0.5,
      ease: "easeInOut",
    },
  }),
};

const CatalogPage = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [allProducts, setAllProducts] = useState([]); // Store all products
  const [filteredProducts, setFilteredProducts] = useState([]); // Store filtered products
  const [preloadedImages, setPreloadedImages] = useState([]); // Preloaded images

  // Fetch products from the API
  useEffect(() => {
    const getProducts = async () => {
      try {
        const data = await fetchProduct();
        setAllProducts(data); // Store all products
        setFilteredProducts(data); // Initialize filtered products with all products

        // Preload all images
        const images = data.images.map((product) => {
          const img = new Image();
          img.src = product.img;
          return img;
        });
        setPreloadedImages(images);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    getProducts();
  }, []);

  // Filter products based on search term
  useEffect(() => {
    const filtered = allProducts.filter((product) =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchTerm, allProducts]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Helmet>
          <title>Product Catalog | Okay Artist</title>
          <meta
            name="description"
            content="Explore our wide selection of custom-designed products at Okay Artist. Find the perfect item for yourself or as a gift."
          />
          <meta
            name="keywords"
            content="custom products, t-shirts, catalog, okay artist, gifts, apparel"
          />
          <meta property="og:title" content="Product Catalog | Okay Artist" />
          <meta
            property="og:description"
            content="Explore our catalog of custom-designed apparel and more at Okay Artist."
          />
          <meta
            property="og:url"
            content="https://www.okayartist.com/catalog"
          />
          <meta
            property="og:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Product Catalog | Okay Artist" />
          <meta
            name="twitter:description"
            content="Discover custom-designed products and apparel at Okay Artist."
          />
          <meta
            name="twitter:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
        </Helmet>

        <Header />
        <Container sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
          <Box sx={{ mb: 4 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search for products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Grid container spacing={4} columns={{ xs: 4, sm: 8, md: 12 }}>
            {filteredProducts.map((product, index) => (
              <Grid item key={product.id} xs={4} sm={4} md={4}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.1 }} // Only animate once, when 30% of the element is visible
                  custom={index}
                  variants={variants}
                >
                  <Card
                    key={product.id}
                    raised
                    onClick={() => {
                      navigate(
                        `/products/${product.listingId}/${product.designId}`
                      );
                    }}
                    sx={{
                      textDecoration: "none",
                      borderRadius: "8px",
                      boxShadow: 3,
                      cursor: "pointer",
                      overflow: "hidden",
                      "&:hover": {
                        boxShadow: 6,
                        transform: "scale(1.05)",
                        transition:
                          "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                      },
                      "&:hover img": {
                        opacity: 0.8,
                      },
                    }}
                  >
                    <CardActionArea>
                      {product.images && product.images.length > 0 && (
                        <CardMedia
                          key={product.id}
                          component="img"
                          sx={{
                            borderRadius: "8px 8px 0 0",
                            transition: "opacity 0.3s ease-in-out",
                          }}
                          image={product.images[0].img}
                          alt={product.name}
                        />
                      )}
                      <CardContent>
                        <Typography variant="h6">{product.title}</Typography>
                        <Typography variant="body1" color="primary">
                          ${product.sizes[0].price}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default CatalogPage;
