// images.js
import gildan18000Black from "../../assets/mockups/Sweatshirt/gildan-18000-black.png";
import gildan18000White from "../../assets/mockups/Sweatshirt/gildan-18000-white.png";
import gildan18000Royal from "../../assets/mockups/Sweatshirt/gildan-18000-royal.png";
import gildan18000Navy from "../../assets/mockups/Sweatshirt/gildan-18000-navy.png";
import gildan18000Purple from "../../assets/mockups/Sweatshirt/gildan-18000-purple.png";
import gildan18000Red from "../../assets/mockups/Sweatshirt/gildan-18000-red.png";
import gildan18000LightBlue from "../../assets/mockups/Sweatshirt/gildan-18000-light-blue.png";
import gildan18000LightPink from "../../assets/mockups/Sweatshirt/gildan-18000-light-pink.png";
import gildan18000DarkHeather from "../../assets/mockups/Sweatshirt/gildan-18000-dark-heather.png";
import gildan18000SportGray from "../../assets/mockups/Sweatshirt/gildan-18000-sport-gray.png";
import gildan18000MilitaryGreen from "../../assets/mockups/Sweatshirt/gildan-18000-military-green.png";

import gildan18000BlackFabric from "../../assets/mockups/Sweatshirt/gildan-18000-black-fabric.png";
import gildan18000WhiteFabric from "../../assets/mockups/Sweatshirt/gildan-18000-white-fabric.png";
import gildan18000RoyalFabric from "../../assets/mockups/Sweatshirt/gildan-18000-royal-fabric.png";
import gildan18000NavyFabric from "../../assets/mockups/Sweatshirt/gildan-18000-navy-fabric.png";
import gildan18000PurpleFabric from "../../assets/mockups/Sweatshirt/gildan-18000-purple-fabric.png";
import gildan18000RedFabric from "../../assets/mockups/Sweatshirt/gildan-18000-red-fabric.png";
import gildan18000LightBlueFabric from "../../assets/mockups/Sweatshirt/gildan-18000-light-blue-fabric.png";
import gildan18000LightPinkFabric from "../../assets/mockups/Sweatshirt/gildan-18000-light-pink-fabric.png";
import gildan18000DarkHeatherFabric from "../../assets/mockups/Sweatshirt/gildan-18000-dark-heather-fabric.png";
import gildan18000SportGrayFabric from "../../assets/mockups/Sweatshirt/gildan-18000-sport-gray-fabric.png";
import gildan18000MilitaryGreenFabric from "../../assets/mockups/Sweatshirt/gildan-18000-military-green-fabric.png";

import bellaCanvas3408Black from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-black.png";
import bellaCanvas3408White from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-white.png";
import bellaCanvas3408Navy from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-navy.png";
import bellaCanvas3408Red from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-red.png";
import bellaCanvas3408Aqua from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-aqua.png";
import bellaCanvas3408Charcoal from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-charcoal.png";

import bellaCanvas3408BlackFabric from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-black-fabric.png";
import bellaCanvas3408WhiteFabric from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-white-fabric.png";
import bellaCanvas3408NavyFabric from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-navy-fabric.png";
import bellaCanvas3408RedFabric from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-red-fabric.png";
import bellaCanvas3408AquaFabric from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-aqua-fabric.png";
import bellaCanvas3408CharcoalFabric from "../../assets/mockups/BellaCanvas3408Mockup/bellacanvas3408-charcoal-fabric.png";

//gildan 18500
import gildan18500Black from "../../assets/mockups/Gildan18500Mockups/gildan18500-black.png";
import gildan18500White from "../../assets/mockups/Gildan18500Mockups/gildan18500-white.png";
import gildan18500Royal from "../../assets/mockups/Gildan18500Mockups/gildan18500-royal.png";
import gildan18500Navy from "../../assets/mockups/Gildan18500Mockups/gildan18500-navy.png";
import gildan18500Purple from "../../assets/mockups/Gildan18500Mockups/gildan18500-purple.png";
import gildan18500Red from "../../assets/mockups/Gildan18500Mockups/gildan18500-red.png";
import gildan18500LightBlue from "../../assets/mockups/Gildan18500Mockups/gildan18500-light-blue.png";
import gildan18500LightPink from "../../assets/mockups/Gildan18500Mockups/gildan18500-light-pink.png";
import gildan18500MilitaryGreen from "../../assets/mockups/Gildan18500Mockups/gildan18500-military-green.png";
import gildan18500DarkHeather from "../../assets/mockups/Gildan18500Mockups/gildan18500-dark-heather.png";
import gildan18500SportGray from "../../assets/mockups/Gildan18500Mockups/gildan18500-sport-gray.png";

import gildan18500BlackFabric from "../../assets/mockups/Gildan18500Mockups/gildan18500-black-fabric.png";
import gildan18500WhiteFabric from "../../assets/mockups/Gildan18500Mockups/gildan18500-white-fabric.png";
import gildan18500RoyalFabric from "../../assets/mockups/Gildan18500Mockups/gildan18500-royal-fabric.png";
import gildan18500NavyFabric from "../../assets/mockups/Gildan18500Mockups/gildan18500-navy-fabric.png";
import gildan18500PurpleFabric from "../../assets/mockups/Gildan18500Mockups/gildan18500-purple-fabric.png";
import gildan18500RedFabric from "../../assets/mockups/Gildan18500Mockups/gildan18500-red-fabric.png";
import gildan18500LightBlueFabric from "../../assets/mockups/Gildan18500Mockups/gildan18500-light-blue-fabric.png";
import gildan18500LightPinkFabric from "../../assets/mockups/Gildan18500Mockups/gildan18500-light-pink-fabric.png";
import gildan18500MilitaryGreenFabric from "../../assets/mockups/Gildan18500Mockups/gildan18500-military-green-fabric.png";
import gildan18500DarkHeatherFabric from "../../assets/mockups/Gildan18500Mockups/gildan18500-dark-heather-fabric.png";
import gildan18500SportGrayFabric from "../../assets/mockups/Gildan18500Mockups/gildan18500-sport-gray-fabric.png";

//bella canvas 3001
import bellaCanvas3001Black from "../../assets/mockups/bella-canvas-3001-black.png";
import bellaCanvas3001White from "../../assets/mockups/bella-canvas-3001-white.png";
import bellaCanvas3001Red from "../../assets/mockups/bella-canvas-3001-red.png";
import bellaCanvas3001Pink from "../../assets/mockups/bella-canvas-3001-pink.png";
import bellaCanvas3001LightBlue from "../../assets/mockups/bella-canvas-3001-light-blue.png";
import bellaCanvas3001DarkHeather from "../../assets/mockups/bella-canvas-3001-dark-heather.png";

import bellaCanvas3001BlackFabric from "../../assets/mockups/fabric-black.png";
import bellaCanvas3001WhiteFabric from "../../assets/mockups/fabric-white.png";
import bellaCanvas3001RedFabric from "../../assets/mockups/fabric-red.png";
import bellaCanvas3001DarkHeatherFabric from "../../assets/mockups/fabric-dark-heather.png";
import bellaCanvas3001PinkFabric from "../../assets/mockups/fabric-pink.png";
import bellaCanvas3001LightBlueFabric from "../../assets/mockups/fabric-light-blue.png";

export const imagesGildan18000 = [
  { img: gildan18000Black, title: "Black" },
  { img: gildan18000White, title: "White" },
  { img: gildan18000Royal, title: "Royal" },
  { img: gildan18000Navy, title: "Navy" },
  { img: gildan18000Purple, title: "Purple" },
  { img: gildan18000Red, title: "Red" },
  { img: gildan18000LightBlue, title: "Light Blue" },
  { img: gildan18000LightPink, title: "Light Pink" },
  { img: gildan18000DarkHeather, title: "Dark Heather" },
  { img: gildan18000SportGray, title: "Sport Gray" },
  { img: gildan18000MilitaryGreen, title: "Military Green" },
];

export const imagesGildan18000Fabric = [
  { img: gildan18000BlackFabric, title: "Black" },
  { img: gildan18000WhiteFabric, title: "White" },
  { img: gildan18000RoyalFabric, title: "Royal" },
  { img: gildan18000NavyFabric, title: "Navy" },
  { img: gildan18000PurpleFabric, title: "Purple" },
  { img: gildan18000RedFabric, title: "Red" },
  { img: gildan18000LightBlueFabric, title: "Light Blue" },
  { img: gildan18000LightPinkFabric, title: "Light Pink" },
  { img: gildan18000DarkHeatherFabric, title: "Dark Heather" },
  { img: gildan18000SportGrayFabric, title: "Sport Gray" },
  { img: gildan18000MilitaryGreenFabric, title: "Military Green" },
];

export const imagesBellaCanvas3408 = [
  {
    img: bellaCanvas3408Black,
    title: "Black",
  },
  { img: bellaCanvas3408Red, title: "Red" },
  { img: bellaCanvas3408White, title: "White" },
  { img: bellaCanvas3408Navy, title: "Navy" },
  { img: bellaCanvas3408Charcoal, title: "Charcoal" },
  { img: bellaCanvas3408Aqua, title: "Aqua" },
];

export const imagesBellaCanvas3408Fabric = [
  { img: bellaCanvas3408BlackFabric, title: "Black" },
  { img: bellaCanvas3408RedFabric, title: "Red" },
  { img: bellaCanvas3408WhiteFabric, title: "White" },
  { img: bellaCanvas3408NavyFabric, title: "Navy" },
  { img: bellaCanvas3408CharcoalFabric, title: "Charcoal" },
  { img: bellaCanvas3408AquaFabric, title: "Aqua" },
];

//gildan 18500 array
export const imagesGildan18500 = [
  {
    img: gildan18500Black,
    title: "Black",
  },
  { img: gildan18500Red, title: "Red" },
  { img: gildan18500White, title: "White" },
  { img: gildan18500Navy, title: "Navy" },
  { img: gildan18500DarkHeather, title: "Dark Heather" },
  { img: gildan18500Royal, title: "Royal" },
  { img: gildan18500Purple, title: "Purple" },
  { img: gildan18500LightPink, title: "Light Pink" },
  { img: gildan18500LightBlue, title: "Light Blue" },
  { img: gildan18500MilitaryGreen, title: "Military Green" },
  { img: gildan18500SportGray, title: "Sport Gray" },
];

export const imagesGildan18500Fabric = [
  { img: gildan18500BlackFabric, title: "Black" },
  { img: gildan18500RedFabric, title: "Red" },
  { img: gildan18500WhiteFabric, title: "White" },
  { img: gildan18500NavyFabric, title: "Navy" },
  { img: gildan18500DarkHeatherFabric, title: "Dark Heather" },
  { img: gildan18500RoyalFabric, title: "Royal" },
  { img: gildan18500PurpleFabric, title: "Purple" },
  { img: gildan18500LightPinkFabric, title: "Light Pink" },
  { img: gildan18500LightBlueFabric, title: "Light Blue" },
  { img: gildan18500MilitaryGreenFabric, title: "Military Green" },
  { img: gildan18500SportGrayFabric, title: "Sport Gray" },
];

//bella canvas 3001 array
export const imagesBellaCanvas3001 = [
  {
    img: bellaCanvas3001Black,
    title: "Black",
  },
  { img: bellaCanvas3001Red, title: "Red" },
  { img: bellaCanvas3001White, title: "White" },
  { img: bellaCanvas3001Pink, title: "Pink" },
  { img: bellaCanvas3001LightBlue, title: "Light Blue" },
  { img: bellaCanvas3001DarkHeather, title: "Dark Heather" },
];

export const imagesBellaCanvas3001Fabric = [
  { img: bellaCanvas3001BlackFabric, title: "Black" },
  { img: bellaCanvas3001RedFabric, title: "Red" },
  { img: bellaCanvas3001WhiteFabric, title: "White" },
  { img: bellaCanvas3001PinkFabric, title: "Pink" },
  { img: bellaCanvas3001DarkHeatherFabric, title: "Dark Heather" },
  { img: bellaCanvas3001LightBlueFabric, title: "Light Blue" },
];
