import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  address: {},
};

export const addressSlice = createSlice({
  name: "adress",
  initialState,
  reducers: {
    setAdress: (state, action) => {
      state = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const { setAdress } = addressSlice.actions;

export default addressSlice.reducer;
