import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Header from "./Header";
import Footer from "./Footer";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { pageVariants, pageTransition } from "./Animations";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";

const ContactUsPage = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackType, setFeedbackType] = useState(""); // Can be "success" or "error"
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.okayartist.com/api/contact-us/",
        formValues,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        // alert("Your message has been sent successfully!");
        // Clear the form or provide further feedback to the user
        setFeedbackMessage("Your message has been sent successfully!");
        setFeedbackType("success");
        setFormValues({
          name: "",
          email: "",
          message: "",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("There was an error sending the message:", error);
      setFeedbackMessage(
        "There was an error sending your message. Please try again later."
      );
      setFeedbackType("error");
      // alert("There was an error sending your message. Please try again later.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          // backgroundColor: "background.primary",
        }}
      >
        <Helmet>
          <title>Contact Us | Okay Artist</title>
          <meta
            name="description"
            content="Get in touch with us at Okay Artist. We're here to help with your custom design needs."
          />
          <meta
            name="keywords"
            content="contact okay artist, support, custom designs, contact form"
          />
          <meta property="og:title" content="Contact Us | Okay Artist" />
          <meta
            property="og:description"
            content="Reach out to Okay Artist for any questions or support regarding your custom design orders."
          />
          <meta
            property="og:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta
            property="og:url"
            content="https://www.okayartist.com/contact"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Contact Us | Okay Artist" />
          <meta
            name="twitter:description"
            content="Need assistance? Contact Okay Artist for support with your custom designs."
          />
          <meta
            name="twitter:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <Header />
        <AnimatePresence mode="wait">
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <Container component="main" maxWidth="md">
              <Paper
                elevation={3}
                sx={{
                  padding: 4,
                  marginTop: 8,
                }}
              >
                <Typography variant="h4" component="h1" gutterBottom>
                  Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                  We'd love to hear from you! Please fill out the form below and
                  we will get in touch with you shortly.
                </Typography>
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="name"
                        label="Name"
                        fullWidth
                        required
                        value={formValues.name}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        required
                        value={formValues.email}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="message"
                        label="Message"
                        fullWidth
                        required
                        multiline
                        rows={4}
                        value={formValues.message}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        endIcon={
                          loading ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={24}
                            />
                          ) : (
                            <SendIcon />
                          )
                        }
                        disabled={loading}
                        fullWidth
                      >
                        Send Message
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {feedbackMessage && (
                        <Typography
                          variant="body2"
                          color={feedbackType === "success" ? "green" : "red"}
                          align="center"
                          sx={{ mt: 2 }}
                        >
                          {feedbackMessage}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Container>
          </motion.div>
        </AnimatePresence>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default ContactUsPage;
