import React from "react";
import Header from "./Header";
import { Box, Container, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const ShippingPolicy = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {/* Meta Tags for Shipping Policy */}
      <Helmet>
        <title>Shipping Policy | Okay Artist</title>
        <meta
          name="description"
          content="Read our shipping policy to learn about delivery times, costs, and international shipping at Okay Artist."
        />
        <meta
          name="keywords"
          content="shipping policy, delivery, shipping costs, okay artist, international shipping"
        />
        <meta property="og:title" content="Shipping Policy | Okay Artist" />
        <meta
          property="og:description"
          content="Learn about Okay Artist's shipping policy, including delivery times, shipping methods, and international shipping options."
        />
        <meta
          property="og:url"
          content="https://www.okayartist.com/shipping-policy"
        />
        <meta
          property="og:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Shipping Policy | Okay Artist" />
        <meta
          name="twitter:description"
          content="Discover our shipping policy, delivery times, and shipping costs for Okay Artist products."
        />
        <meta
          name="twitter:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
      </Helmet>
      <Header />
      <Container
        sx={{
          // backgroundColor: "gray",

          mt: "5%",
          mb: "5%",
        }}
      >
        <Stack
          direction={"column"}
          spacing={8}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Shipping policy</Typography>
          <Divider orientation="horizontal" flexItem />
          <Stack direction={"column"} spacing={2} justifyContent={"center"}>
            <Typography variant="body1">
              {`1. Delivery Timeframe: We aim to deliver all orders within 5-10 business days from the date of purchase.`}
            </Typography>
            <Typography variant="body1">
              {`2. Shipping Costs: Shipping costs are calculated based on the weight of the order and the delivery location. We offer free shipping for orders over a certain amount.`}
            </Typography>
            <Typography variant="body1">
              {`3. Shipping Method: We use reliable courier services to ensure timely delivery of your order. You will receive a tracking number to monitor the progress of your shipment.`}
            </Typography>
            <Typography variant="body1">
              {`4. Delivery Address: Please provide a complete and accurate shipping address, including postal code and email address.`}
            </Typography>
            <Typography variant="body1">
              {`5. International Shipping: We currently only ship to most of countries in the world.`}
            </Typography>
            <Typography variant="body1">
              {`6. Order Processing: Orders are processed within 3-5 business days, excluding weekends and holidays.`}
            </Typography>
            <Typography variant="body1">
              {`7. Damaged or Lost Shipments: If you receive a damaged or lost shipment, please contact us immediately so we can resolve the issue.`}
            </Typography>
          </Stack>
          {/* <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Exchanges
            </Typography>
            <Typography variant="body1">
              {`Unfortunately, we don't accept exchanges as most of the products are personalized, but please contact us if there's something wrong with the product.`}
            </Typography>
          </Box> */}
        </Stack>
      </Container>
      <Footer></Footer>
    </Box>
  );
};

export default ShippingPolicy;
