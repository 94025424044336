import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Alert,
  CssBaseline,
  Box,
} from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import { Helmet } from "react-helmet";

export default function SignupPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");

  //   async function signupUser(userData) {
  //     // userData should be an object containing the user details
  //     // For example: { username: 'user1', email: 'email@example.com', password: 'password123' }

  //     try {
  //       const response = await fetch("http://localhost:8000/auth/users/", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(userData),
  //       });

  //       if (!response.ok) {
  //         // If the server response is not ok, throw an error
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }

  //       const data = await response.json(); // Assuming the server responds with JSON
  //       console.log("User registered successfully:", data);
  //       return data; // Return the response data
  //     } catch (error) {
  //       console.error("Error during user registration:", error);
  //       throw error; // Propagate the error
  //     }
  //   }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Client-side validation for password confirmation
    if (formData.password !== formData.confirmPassword) {
      console.error("Passwords do not match.");
      // Handle the error (e.g., display a message to the user)
      return;
    }

    // Prepare the data for Djoser
    const userData = {
      username: formData.username,
      email: formData.email, // Uncomment if email is required
      password: formData.password,
    };
    console.log(userData);

    try {
      const response = await axios.post(
        "https://api.okayartist.com/auth/users/",
        // "http://127.0.0.1:8000/auth/users/",

        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/login");
      console.log("User registered successfully:", response.data);
      // Handle successful registration (e.g., redirect to login page)
    } catch (error) {
      console.error(
        "Error during user registration:",
        error.response ? error.response.data : error
      );
      // Handle the registration error
    }
    setLoading(false);
  };

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/; // Simple email regex pattern
    return emailRegex.test(email);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Helmet>
          <title>Sign Up | Okay Artist</title>
          <meta
            name="description"
            content="Create an account with Okay Artist to explore and purchase unique custom design products."
          />
          <meta
            name="keywords"
            content="sign up, create account, Okay Artist, custom design, register"
          />
          <meta property="og:title" content="Sign Up | Okay Artist" />
          <meta
            property="og:description"
            content="Join Okay Artist and create your account to start exploring custom design products today."
          />
          <meta property="og:url" content="https://www.okayartist.com/signup" />
          <meta
            property="og:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Sign Up | Okay Artist" />
          <meta
            name="twitter:description"
            content="Create your Okay Artist account and discover custom design products."
          />
          <meta
            name="twitter:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
        </Helmet>
        <CssBaseline />
        <Header></Header>
        <Container
          component="main"
          sx={{ mt: 8, mb: 2, flexGrow: 1 }}
          maxWidth={"xs"}
        >
          <div
            style={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleSubmit} style={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={formData.username}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={formData.password}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!isValidEmail(formData.email) || loading}
                endIcon={
                  loading ? (
                    <CircularProgress sx={{ color: "white" }} size={24} />
                  ) : null
                }
              >
                {!loading ? "Sign Up" : "Registering..."}
                {/* Sign Up */}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>{/* Link to sign-in page */}</Grid>
              </Grid>
            </form>
          </div>
        </Container>
        <Footer></Footer>
      </Box>
    </ThemeProvider>
  );
}
