import { createSlice } from "@reduxjs/toolkit";

export const CanvasRefSlice = createSlice({
  name: "CanvasRef",
  initialState: {
    CanvasRef: "",
  },
  reducers: {
    setCanvasRef: (state, action) => {
      state.CanvasRef = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCanvasRef } = CanvasRefSlice.actions;

export default CanvasRefSlice.reducer;
