import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
import { unsetGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";
import { fabric } from "fabric";
// import { enhanceImage } from "./image-processing-tools";
import { setImageURL } from "./redux-slices/ImageSlice";

import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { CanvasContext } from "./CanvasContext";

const ImageExposurePanel = (props) => {
  const { canvasRef } = useContext(CanvasContext);
  const dispatch = useDispatch();
  const [brightness, setBrightness] = useState(0);
  const [contrast, setContrast] = useState(0);
  const [highlights, setHighlights] = useState(0);
  const [shadows, setShadows] = useState(0);
  // const [backButton, setBackButton] = useState(false);

  const fabricCanvas = canvasRef.current.fabric;
  // fabricCanvas.setDimensions({ width: imgWidth, height: imgHeight });
  console.log("BREAKPOINT --------- imageExposure " + fabricCanvas.width);
  // const fabricCanvas = new fabric.Canvas(canvasRef.current);

  // const originalImageURL = useSelector((state) => state.image.imageURL); // Access the image URL from the Redux store

  const handleChange = (event, newValue, name) => {
    console.log(`${name}: ${newValue}`);
    if (name == "Brightness") setBrightness(newValue);
    else if (name == "Contrast") setContrast(newValue);
    else if (name == "Highlights") setHighlights(newValue);
    else if (name == "Shadows") setShadows(newValue);

    // You can set the state here or call a function to handle the change.
  };

  // normalize values between -1 and 1
  function normalize(x, min, max) {
    return (2 * (x - min)) / (max - min) - 1;
  }
  //remove filters if the back button is clicked
  const back = () => {
    // dispatch(setImageURL(originalImageURL));
    const imageOriginal = fabricCanvas
      .getObjects()
      .find((obj) => obj.type === "image" && obj.myCustomId === "image");
    if (!imageOriginal || !(imageOriginal instanceof fabric.Image)) {
      console.log("Image object not found");
      return;
    }
    let image = imageOriginal;
    image.filters = [];
    image.applyFilters();
    fabricCanvas.renderAll();
  };

  // apply image manipulation filters
  useEffect(() => {
    if (!fabricCanvas) {
      console.log("Canvas not found");
      return;
    }
    const imageOriginal = fabricCanvas
      .getObjects()
      .find((obj) => obj.type === "image" && obj.myCustomId === "image");
    if (!imageOriginal || !(imageOriginal instanceof fabric.Image)) {
      console.log("Image object not found");
      return;
    }

    let image = imageOriginal;
    image.filters = [];
    // Apply Brightness filter
    const brightnessNormalized = normalize(brightness, -100, 100);
    const brightnessFilter = new fabric.Image.filters.Brightness({
      brightness: brightnessNormalized, // Adjust brightness
    });

    // Apply Contrast filter
    const contrastNormalized = normalize(contrast, -100, 100); // Normalize your contrast value
    const contrastFilter = new fabric.Image.filters.Contrast({
      contrast: contrastNormalized, // Value between -1 and 1
    });

    // For highlights and shadows, we adjust brightness and contrast
    // Since Fabric.js does not provide direct filters for highlights and shadows,
    // these adjustments can be an approximation.
    // Further customization may require implementing custom shaders or adjusting existing filters.

    // Apply modified Brightness for highlights (using your brightness value or a separate one)
    // Note: This is an approximation. Actual highlights adjustment might require different logic.
    const highlightsFilter = new fabric.Image.filters.Brightness({
      brightness: normalize(highlights, -100, 100), // Adjust highlights
    });

    // Apply modified Contrast for shadows (using your contrast value or a separate one)
    // Note: This is an approximation. Actual shadows adjustment might require different logic.
    const shadowsFilter = new fabric.Image.filters.Contrast({
      contrast: normalize(shadows, -100, 100), // Adjust shadows
    });

    // Add filters to image and apply
    image.filters.push(
      brightnessFilter,
      contrastFilter,
      highlightsFilter,
      shadowsFilter
    );
    image.applyFilters();
    fabricCanvas.renderAll();
  }, [brightness, contrast, highlights, shadows]);

  //function to display adjusted image
  const displayImage = () => {
    const imageOriginal = fabricCanvas
      .getObjects()
      .find((obj) => obj.type === "image" && obj.myCustomId === "image");
    const croppedDataURL = imageOriginal.toDataURL({
      // scaleX: scaleGlobal,
      // scaleY: scaleGlobal,
      // quality: 1,
      // left: 0,
      // top: 0,
      // width: width,
      // height: height,
      // originX: "left", // Set origin to the top-left corner
      // originY: "top", // Set origin to the top-left corner
    });
    dispatch(setImageURL(croppedDataURL));
  };

  return (
    <Box>
      <Button
        onClick={() => {
          // dispatch(setBackMenu());
          back();
          dispatch(unsetGridMenuClicked());
        }}
      >
        <ArrowBackIcon></ArrowBackIcon>
      </Button>
      <Box style={{ padding: "5%" }}>
        <Stack direction="column" spacing={2}>
          <Box>
            <Typography id="brightness-slider" variant="caption" gutterBottom>
              Brightness
            </Typography>
            <Slider
              defaultValue={0}
              getAriaValueText={(value) => `${value}%`}
              aria-labelledby="brightness-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={-100}
              max={100}
              onChange={(e, val) => handleChange(e, val, "Brightness")}
            />

            <Typography id="contrast-slider" variant="caption" gutterBottom>
              Contrast
            </Typography>
            <Slider
              defaultValue={0}
              getAriaValueText={(value) => `${value}%`}
              aria-labelledby="contrast-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={-100}
              max={100}
              onChange={(e, val) => handleChange(e, val, "Contrast")}
            />

            <Typography id="highlights-slider" variant="caption" gutterBottom>
              Highlights
            </Typography>
            <Slider
              defaultValue={0}
              getAriaValueText={(value) => `${value}%`}
              aria-labelledby="highlights-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={-100}
              max={100}
              onChange={(e, val) => handleChange(e, val, "Highlights")}
            />

            <Typography id="shadows-slider" variant="caption" gutterBottom>
              Shadows
            </Typography>
            <Slider
              defaultValue={0}
              getAriaValueText={(value) => `${value}%`}
              aria-labelledby="shadows-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={-100}
              max={100}
              onChange={(e, val) => handleChange(e, val, "Shadows")}
            />
          </Box>
          <Button
            onClick={() => {
              displayImage();
              dispatch(unsetGridMenuClicked());
            }}
            variant="contained"
          >
            Apply
          </Button>
          <Button
            onClick={() => {
              dispatch(unsetGridMenuClicked());
              back();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
export default ImageExposurePanel;
