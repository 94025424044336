import React from "react";
import Header from "./Header";
import { Box, Container, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const ContactInformation = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {/* Meta Tags for Contact Information */}
      <Helmet>
        <title>Contact Information | Okay Artist</title>
        <meta
          name="description"
          content="Get in touch with Okay Artist. Find our contact information, including email and mailing address."
        />
        <meta
          name="keywords"
          content="contact information, okay artist, email, address, help@okayartist.com"
        />
        <meta property="og:title" content="Contact Information | Okay Artist" />
        <meta
          property="og:description"
          content="Find contact information for Okay Artist, including email and address."
        />
        <meta
          property="og:url"
          content="https://www.okayartist.com/contact-information"
        />
        <meta
          property="og:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contact Information | Okay Artist"
        />
        <meta
          name="twitter:description"
          content="Get in touch with Okay Artist. Contact us via email or mailing address."
        />
        <meta
          name="twitter:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
      </Helmet>
      <Header />
      <Container
        sx={{
          // backgroundColor: "gray",

          mt: "5%",
          mb: "5%",
        }}
      >
        <Stack
          direction={"column"}
          spacing={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Contact information</Typography>
          <Divider orientation="horizontal" flexItem />
          <Stack direction={"row"} spacing={1}>
            <Typography variant="body1" sx={{ mb: "2%" }}>
              Name:
            </Typography>
            <Typography variant="body1">Okay Artist</Typography>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <Typography variant="body1" sx={{ mb: "2%" }}>
              Email:
            </Typography>
            <Typography variant="body1">
              <a href="mailto:help@okayartist.com">help@okayartist.com</a>
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <Typography variant="body1" sx={{ mb: "2%" }}>
              Address:
            </Typography>
            <Typography variant="body1">
              4283 Express Lane, Suite 7320-295, Sarasota FL 34249, United
              States
            </Typography>
          </Stack>

          {/* <Box>
            <Typography variant="h6" sx={{ mb: "2%" }}>
              Exchanges
            </Typography>
            <Typography variant="body1">
              {`Unfortunately, we don't accept exchanges as most of the products are personalized, but please contact us if there's something wrong with the product.`}
            </Typography>
          </Box> */}
        </Stack>
      </Container>
      <Footer></Footer>
    </Box>
  );
};

export default ContactInformation;
