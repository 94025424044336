import React, { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "@mui/material/ToggleButton";

import {
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
  ToggleButtonGroup,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PaymentIcon from "@mui/icons-material/Payment";
import ShareIcon from "@mui/icons-material/Share";

import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  setBasketProduct,
  initializeProduct,
  clearUpProduct,
  setListing,
} from "./redux-slices/ProductSlice";
import { useNavigate } from "react-router-dom";
import { addItem } from "./redux-slices/BasketSlice";
import {
  fetchProductDetails,
  fetchCoreProductDetails,
} from "./fetchProductDetails";
import { List, ListItem, ListItemText } from "@mui/material";

import ListItemIcon from "@mui/material/ListItemIcon";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CustomerReviews from "./CustomerReviews";
import { useMediaQuery } from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import ProductImageComponent from "./ProductImagesComponent";
import { useParams, useLocation } from "react-router-dom";
import { CanvasContext } from "./CanvasContext";
import { Helmet } from "react-helmet";
import { useRenderCount } from "./useRenderCounter";

const ProductLandingComponent = () => {
  // useRenderCount();
  // console.log("BEGGINING OF PRODUCT PAGE");
  const { listingId, designId } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const imgMockupURL = useSelector((state) => state.image.imgMockupURL);
  const productPreviewMockupURL = useSelector(
    (state) => state.image.productPreviewMockupURL
  );
  const [imgProduct, setImgProduct] = React.useState(productPreviewMockupURL);
  const product = useSelector((state) => state.product.product);
  const listing = useSelector((state) => state.product.listing);
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const designFileURL = useSelector((state) => state.image.designFileURL);

  const { setProductCanvasActive } = React.useContext(CanvasContext);

  const [alignment, setAlignment] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [quantity, setQuantity] = React.useState(1); // Start with a default value of 1
  const [totalPrice, setTotalPrice] = React.useState(price * quantity);
  const navigate = useNavigate();

  const [productFetched, setProductFetched] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [productImg, setProductImg] = React.useState(imgMockupURL);
  const [color, setColor] = React.useState("");
  const [sizes, setSizes] = React.useState([]);
  const [reviews, setReviews] = React.useState([]);
  const [preloadedImages, setPreloadedImages] = React.useState([]); // Preloaded images
  const [images, setImages] = React.useState([]);
  const unmountedRef = useRef(false); // Track if component is unmounting

  //function that updates the product state in redux store
  // const updateProduct = (name, value) => {
  //   dispatch(setBasketProduct({ name, value }));
  // };
  const updateProduct = useCallback(
    (name, value) => {
      dispatch(setBasketProduct({ name, value }));
    },
    [dispatch]
  );

  // const updateListing = (name, value) => {
  //   dispatch(setListing({ name, value }));
  // };
  const updateListing = useCallback(
    (name, value) => {
      dispatch(setListing({ name, value }));
    },
    [dispatch]
  );

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };
  //this need to be changed according to listingid
  // const listingId = "custom-retro-tshirtbellacanvas3001";

  //fetch dynamodb for product
  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const data = await fetchProductDetails(listingId);

        // Preload all images
        const images = data.images.map((product) => {
          const img = new Image();
          img.src = product.img;
          return img;
        });
        setPreloadedImages(images); // Store preloaded images
        setImages(data.images);
        setProductFetched(data);
        setAlignment(data.sizes[0].size);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getProductDetails();

    return () => {
      unmountedRef.current = true;
      setProductCanvasActive(false);
      if (unmountedRef.current) {
        // dispatch(setProductPreviewMockupURL(""));
        // setProductImgURL("");
      }
      // console.log("EXITING PRODUCT PAGE");
      // console.log(
      //   "BREAKPOINT productPreviewMockupURL= " + productPreviewMockupURL
      // );

      // setImgProduct("");
    };
  }, []);

  //retrieve product related images
  // React.useEffect(() => {
  //   const getCoreProductDetails = async () => {
  //     try {
  //       const data = await fetchCoreProductDetails(productFetched.brand);

  //       // Preload all images
  //       const images = data.map((product) => {
  //         const img = new Image();
  //         img.src = product.img;
  //         return img;
  //       });
  //       setPreloadedImages(images); // Store preloaded images
  //       setImages((images) => [...images, productFetched.images]);
  //       setImages((images) => [...images, data]);
  //       // setCoreProduct(data);
  //     } catch (error) {
  //       // setError(error);
  //     } finally {
  //       // setLoading(false);
  //     }
  //   };
  //   // setProductImages((productImages) => [...productImages, images]);
  //   getCoreProductDetails();
  // }, [productFetched]);

  // console.log("BREAKPOINT productPreviewMockupURL= " + productPreviewMockupURL);

  //
  useEffect(() => {
    setImgProduct(productPreviewMockupURL);
    if (productPreviewMockupURL != "") {
      updateProduct("mockupImageURL", productPreviewMockupURL);
      if (designFileURL != "") updateProduct("designURL", designFileURL);
    }
  }, [productPreviewMockupURL]);

  //set the sizes and prices of product in product landing page.
  useEffect(() => {
    if (productFetched && alignment) {
      setSizes(productFetched.sizes);
      setPrice(
        productFetched.sizes.find((element) => element.size === alignment).price
      );
      // console.log("BREAKPOINT" + JSON.stringify(productFetched.reviews[0]));
      setReviews(productFetched.reviews);
    }
    // setReviews(productFetched.reviews);
  }, [productFetched, alignment]);

  useEffect(() => {
    if (productFetched) {
      updateListing("title", productFetched.title);
      updateListing("description", productFetched.description);
      updateListing("brand", productFetched.brand);
      updateListing("listingId", productFetched.listingId);
      updateListing("unitPrice", productFetched.unitPrice);
      updateListing("sizes", productFetched.sizes);
      updateListing("shippingRates", productFetched.shippingRates);

      if (productPreviewMockupURL == "")
        updateProduct("mockupImageURL", productFetched.images[0].img);

      // setImages(productFetched.images);
      // console.log("BREAKPOINT images= " + productFetched.images);
    }
  }, [productFetched]);

  useEffect(() => {
    updateProduct("listing", listing);
  }, [product]);

  //handle buynow button
  const handleBuyNow = () => {
    // dispatch(initializeProduct()); // Initialize the product with a unique ID
    dispatch(addItem(product));
    navigate("/purchase");
  };

  const handleChangeToggleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const control = {
    value: alignment,
    onChange: handleChangeToggleAlignment,
    exclusive: true,
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1)); // Prevent going below 1
  };

  //initial effect for image ulr and listing details
  useEffect(() => {
    // updateProduct("mockupImageURL", imgMockupURL);
    updateProduct("listing", listing);
    updateProduct("listingId", listingId);
    return () => {
      dispatch(clearUpProduct());
    };
  }, []);

  //quantity change effect
  useEffect(() => {
    updateProduct("quantity", quantity);
    setTotalPrice(quantity * price);
    updateProduct("totalPrice", quantity * price);
  }, [quantity, price]);

  //size change effect
  useEffect(() => {
    updateProduct("size", alignment);
  }, [alignment]);

  //update color in product redux
  useEffect(() => {
    updateProduct("color", color);
  }, [color]);

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue) && newValue > 0) {
      setQuantity(newValue);
      // updateProduct("quantity", quantity);
    } else {
      // updateProduct("quantity", quantity);
      setQuantity(1); // Reset to 1 if invalid input
    }
  };

  const handleAddToCart = () => {
    dispatch(initializeProduct()); // Initialize the product with a unique ID
  };

  //adds product to basket items state
  useEffect(() => {
    if (product.id !== "") {
      dispatch(addItem(product));
    }
    // console.log("BREAKPOINT checking uuid= " + JSON.stringify(product.listing));
    // console.log("BREAKPOINT checking product= " + product.size);
    // console.log("BREAKPOINT checking items= " + JSON.stringify(items));
  }, [product.id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const clickedButton = event.nativeEvent.submitter.name;
    if (clickedButton === "addToCart") {
      handleAddToCart();
    } else if (clickedButton === "buyNow") {
      handleBuyNow();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {productFetched ? (
        <Box>
          {/* Dynamic Meta Tags */}
          <Helmet>
            <title>{productFetched.title} | Okay Artist</title>
            <meta name="description" content={productFetched.description} />
            <meta
              name="keywords"
              content={`buy ${productFetched.title}, custom ${productFetched.brand}`}
            />
            <meta
              property="og:title"
              content={`${productFetched.title} | Okay Artist`}
            />
            <meta
              property="og:description"
              content={productFetched.description}
            />
            <meta property="og:image" content={productFetched.images[0]?.img} />
            <meta
              property="og:url"
              content={`https://www.okayartist.com/products/${listingId}`}
            />
            <meta property="product:price:amount" content={totalPrice} />
            <meta property="product:price:currency" content="USD" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content={`${productFetched.title} | Okay Artist`}
            />
            <meta
              name="twitter:description"
              content={productFetched.description}
            />
            <meta
              name="twitter:image"
              content={productFetched.images[0]?.img}
            />
          </Helmet>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
            }}
          >
            <Grid
              item
              xs={4}
              sm={6}
              md={8}
              sx={{
                marginTop: "5%",
              }}
              //   sx={{ backgroundColor: "red" }}
            >
              <ProductImageComponent
                images={images}
                productImg={productImg}
                setProductImg={setProductImg}
                matchesXS={matchesXS}
                brandName={productFetched.brand}
              />

              {!matchesXS && (
                <Box>
                  <CustomerReviews
                    listingId={listing.listingId}
                    reviews={reviews}
                  />
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={4}
              sm={2}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "gray",
                marginTop: "5%",
                ...(matchesXS ? { ml: 2, mr: 2 } : { ml: "auto", mr: "auto" }),
              }}
              component="form"
              onSubmit={handleSubmit}
            >
              <Stack direction={"column"} spacing={"5%"}>
                <Typography variant="h4" gutterBottom>
                  {productFetched.title}
                </Typography>
                <Typography variant="h6" gutterBottom color="primary">
                  ${totalPrice}
                </Typography>
                <Divider />
                <Stack direction={"column"}>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    Choose Variation
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "5%",
                    }}
                  >
                    <ToggleButtonGroup
                      size="large"
                      {...control}
                      aria-label="Small sizes"
                    >
                      {/* {children} */}
                      {sizes.map((element) => (
                        <ToggleButton value={element.size} key={element.size}>
                          <Typography>{element.size}</Typography>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Box>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    Choose Color
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      ml: 5,
                      mr: 5,
                      // marginBottom: "25%",
                    }}
                  >
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      required
                    >
                      <InputLabel id="color-select-label">Color</InputLabel>
                      <Select
                        labelId="color-select-label"
                        id="color-select"
                        value={color}
                        onChange={handleColorChange}
                        label="Color"
                      >
                        {productFetched.colors.map((color) => (
                          <MenuItem value={color} key={color}>
                            {color}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      // ml: 5,
                      // mr: 5,
                      mt: "5%",
                      marginBottom: "25%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate(
                          `/product-template/${listingId}/${productFetched.brand}/${designId}`
                        );
                      }}
                      variant="contained"
                    >
                      Customize Product
                    </Button>
                  </Box>
                  {/* <CustomProductPreview></CustomProductPreview> */}
                  <Box>
                    {/* <img width={"100%"} height={"auto"} src={imgProduct} /> */}
                    {/* <img width={"100%"} height={"auto"} src={productImgURL} /> */}
                  </Box>

                  {/* <Box sx={{ display: "flex", width: "60%", height: "auto" }}>
                    <CustomProductPreview />
                  </Box> */}
                </Stack>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  variant="subtitle1"
                  gutterBottom
                >
                  Quantity
                </Typography>
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  direction="row"
                  spacing={1}
                >
                  <IconButton variant="outlined" onClick={handleDecrement}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  <TextField
                    size="small"
                    value={quantity}
                    type="number"
                    onChange={handleChange}
                    InputProps={{
                      inputProps: {
                        min: 1, // HTML5 constraint to ensure only positive values
                      },
                    }}
                  />
                  <IconButton variant="outlined" onClick={handleIncrement}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    startIcon={<ShoppingCartIcon />}
                    variant="outlined"
                    sx={{ mt: 1 }}
                    // onClick={handleAddToCart}
                    type="submit"
                    name="addToCart"
                  >
                    Add to Cart
                  </Button>
                  <Button
                    startIcon={<PaymentIcon />}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 1, ml: 1 }}
                    // onClick={handleBuyNow}
                    type="submit"
                    name="buyNow"
                  >
                    Buy It Now
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ mt: 2, whiteSpace: "pre-line" }}
                  >
                    {productFetched.description}
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Product Properties
                  </Typography>
                  <List dense>
                    {productFetched.properties.map((property, index) => (
                      <ListItem key={index} dense disablePadding>
                        <ListItemIcon>
                          <HorizontalRuleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={property} />
                      </ListItem>
                    ))}
                  </List>
                </Box>

                {matchesXS && (
                  <Box>
                    <CustomerReviews
                      listingId={listing.listingId}
                      reviews={reviews}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 1,
                    mt: 1,
                  }}
                >
                  <IconButton aria-label="share on social media">
                    <ShareIcon />
                  </IconButton>
                  {/* More icons can be added for specific social media */}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Typography variant="body1"></Typography>
      )}
    </ThemeProvider>
  );
};

export default ProductLandingComponent;
