import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import axios from "axios";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

export default function ForgotPasswordDialog({ open, onClose }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true); // Start loading
    // Implement your API call for password reset here
    console.log("Requesting password reset for:", email);

    // Prepare the email data for Djoser's password reset
    const emailData = {
      email: email, // The email address of the account
    };
    console.log(emailData);

    try {
      const response = await axios.post(
        "https://api.okayartist.com/auth/users/reset_password/",
        emailData, // Send only the email
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Password reset email sent successfully:", response.data);
      // Handle successful password reset request (e.g., show a message to check their email)
    } catch (error) {
      console.error(
        "Error during password reset request:",
        error.response ? error.response.data : error
      );
      // Handle the error
    }
    setLoading(false); // Stop loading after the response is received
    // Close the dialog
    onClose();
    // Optionally, show a success message or further instructions
  };

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/; // Simple email regex pattern
    return emailRegex.test(email);
  };

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            disabled={!isValidEmail(email) || loading}
            endIcon={loading ? <CircularProgress size={24} /> : null}
          >
            {!loading ? "Request Reset Link" : "Requesting..."}
            {/* Request Reset Link */}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
