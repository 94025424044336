import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Stack,
  Pagination,
} from "@mui/material";
import axios from "axios";
import Rating from "@mui/material/Rating";
import { useMediaQuery } from "@mui/material";

// const tempReview = [
//   {
//     username: "Max Galagher",
//     title: "High Quality",
//     rating: 4,
//     comment: "Super soft, print quality is vibrant!",
//   },
//   {
//     username: "Mr Jackson",
//     title: "High Quality",
//     rating: 5,
//     comment: "Awesome experience! Thank you!",
//   },
// ];

const CustomerReviews = ({ listingId, reviews = [] }) => {
  // const [reviews, setReviews] = useState(tempReview);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 10; // Number of reviews per page

  // Calculate the total number of pages
  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  // Get the reviews for the current page
  const currentReviews = reviews.slice(
    (currentPage - 1) * reviewsPerPage,
    currentPage * reviewsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // if (reviews === null) reviews = [];
  //   setReviews(tempReview);
  //   useEffect(() => {
  //     const fetchReviews = async () => {
  //       try {
  //         const response = await axios.get(
  //           `http://localhost:8000/api/reviews/${listingId}`
  //         );
  //         setReviews(response.data.reviews);
  //       } catch (error) {
  //         setError(error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     };

  //     fetchReviews();
  //   }, [listingId]);

  //   if (loading) {
  //     return <Typography>Loading reviews...</Typography>;
  //   }

  //   if (error) {
  //     return <Typography>Error loading reviews: {error.message}</Typography>;
  //   }
  const hideUsernamePartially = (username) => {
    if (!username) return "";
    const visiblePart = username.slice(0, Math.ceil(username.length / 2));
    const hiddenPart = "*".repeat(username.length - visiblePart.length);
    return `${visiblePart}${hiddenPart}`;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Box sx={{ mt: 20, ml: 4 }}>
      <Typography sx={{ textAlign: "center" }} variant="h6">
        Customer Reviews
      </Typography>
      <List
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "left",
          flexDirection: matchesXS ? "column" : "row",
          columnGap: 10,
          rowGap: 5,
        }}
      >
        {currentReviews.length > 0 ? (
          currentReviews.map((review, index) => (
            <Stack direction={"column"} key={index} spacing={2}>
              <Rating name="read-only" value={review.rating} readOnly />
              <ListItemText
                secondary={
                  <>
                    <Typography variant="body2">
                      {hideUsernamePartially(review.user)}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {formatDate(review.created_at)}
                    </Typography>
                  </>
                }
                primary={<>{review.comment}</>}
              />
            </Stack>
          ))
        ) : (
          <Typography>No reviews yet.</Typography>
        )}
      </List>

      {/* Pagination Controls */}
      {reviews.length > reviewsPerPage && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            variant="outlined"
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomerReviews;
