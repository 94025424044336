import * as React from "react";
import { useState, useContext } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TitleOutlinedIcon from "@mui/icons-material/TitleOutlined";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// import ButtonGroup from "@mui/material/ButtonGroup";
import { Stack } from "@mui/material";
import ColorizeIcon from "@mui/icons-material/Colorize";

import { SketchPicker } from "react-color";
// import { useSelector, useDispatch } from "react-redux";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
// import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CanvasContext } from "../CanvasContext";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Avatar from "@mui/material/Avatar";

import {
  cartoonizerEffect2,
  convertToSketchOpencv,
  imageToVectorBlackAndWhite,
  imageToVectorBlackAndWhite2,
  backgroundRemoverREMBG,
  convertToSketch2,
} from "../image-processing-tools";

import {
  setImageURL,
  setOriginalImageUR,
  setImgTemplateURL,
} from "../redux-slices/ImageSlice";
import { useDispatch, useSelector } from "react-redux";

const filters = [
  // { style: "Cartoonizer", handleFunction: cartoonizerEffect },
  { style: "Cartoonizer 2", handleFunction: cartoonizerEffect2 },
  // { style: "Oil Paint", handleFunction: oilPainting },
  // { style: "Watercolor", handleFunction: watercolorEffect },
  { style: "Sketch", handleFunction: convertToSketchOpencv },
  { style: "Sketch 2", handleFunction: convertToSketch2 },
  // { style: "Style Transfer", handleFunction: styleTransfer },
  // { style: "Style Transfer2", handleFunction: styleTransfer2 },
  // { style: "Line Art", handleFunction: lineArt },
  {
    style: "Vectorize Black & White",
    handleFunction: imageToVectorBlackAndWhite,
  },
  {
    style: "Vectorize Black & White 2",
    handleFunction: imageToVectorBlackAndWhite2,
  },
  {
    style: "Background Remover",
    handleFunction: backgroundRemoverREMBG,
  },
];

export default function ImageFiltersTemplate() {
  const dispatch = useDispatch();
  const {
    textDetails,
    setTextDetails,
    formats,
    setFormats,
    setFont,
    setTextColor,
    textColor,
    setFontSizing,
  } = useContext(CanvasContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElColor, setAnchorElColor] = React.useState(null);
  const [fontWeight, setFontWeight] = React.useState("normal");
  const imgTemplateURL = useSelector((state) => state.image.imgTemplateURL);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickColor = (event) => {
    setShowColorPicker(!showColorPicker);

    setAnchorElColor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseColor = () => {
    setAnchorElColor(null);
  };

  const open = Boolean(anchorEl);
  const openColor = Boolean(anchorElColor);
  const id = open ? "simple-popover" : undefined;
  const idColor = open ? "color-picker" : undefined;

  const theme = useTheme();
  const [fontType, setFontType] = useState("");
  // const [fontSize, setFontSize] = useState(20); // State to keep track of font size
  // const [textColor, setTextColor] = useState("#000000");
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleChange = (event) => {
    setFontType(event.target.value);
    setFont(event.target.value);
    // setFont(event.target.value);
  };

  const handleTextColorChange = (color) => {
    // const canvasRef = document.getElementById("canvas");
    setTextColor(color.hex);
  };
  // const [formats, setFormats] = React.useState(() => [
  //   "bold",
  //   "italic",
  //   "underlined",
  // ]);

  const handleFormat = (event, newFormats) => {
    console.log("BREAKPOINT newformats= " + newFormats);
    setFormats(newFormats);
    // setTextDetails({ fontWeight: newFormats });
  };

  const buttons = [<Button key="one">+</Button>, <Button key="two">-</Button>];

  const [alignment, setAlignment] = React.useState("left");

  const handleChangeToggleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const children = [
    <ToggleButton value="left" key="left">
      <FormatAlignLeftIcon fontSize="small" />
    </ToggleButton>,
    <ToggleButton value="center" key="center">
      <FormatAlignCenterIcon fontSize="small" />
    </ToggleButton>,
    <ToggleButton value="right" key="right">
      <FormatAlignRightIcon fontSize="small" />
    </ToggleButton>,
    <ToggleButton value="justify" key="justify">
      <FormatAlignJustifyIcon fontSize="small" />
    </ToggleButton>,
  ];

  const control = {
    value: alignment,
    onChange: handleChangeToggleAlignment,
    exclusive: true,
  };

  const handleFilterClick = (value) => {
    value
      .handleFunction(imgTemplateURL)
      .then((url) => {
        dispatch(setImgTemplateURL(url));
      })
      .catch((error) => {
        console.log("Error processing the image: " + error);
      });
  };

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
      <Tooltip
        title="Image Effects"
        placement="bottom"
        arrow
        disableInteractive
      >
        <IconButton onClick={handleClick} color="primary">
          <AutoAwesomeIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ padding: "20%" }}
      >
        <Box sx={{ p: 2 }}>
          {/* Apply padding here */}
          {/* <Typography>The content of the Popover.</Typography> */}

          <ThemeProvider theme={theme}>
            <Stack direction="column" dense useFlexGap spacing={1}>
              {filters.map((value) => {
                return (
                  <Button
                    variant="contained"
                    key={value}
                    disablePadding
                    // alignItems="flex-start
                    onClick={() => {
                      handleFilterClick(value);
                    }}
                    divider
                    style={{ justifyContent: "left" }}
                  >
                    <Avatar
                      sx={{
                        width: {
                          xs: 20,
                          sm: 20,
                          md: 25,
                          lg: 30,
                          xl: 40,
                        },
                        // paddingRight: "5%",
                        height: "auto",
                      }}
                      alt={`Avatar n°${value}`}
                      src={require(`../../assets/menu-images/${value.style}.png`)}
                      variant="rounded"
                    />
                    <Typography
                      gutterBottom
                      // variant="button"
                      sx={{
                        fontSize: {
                          xs: "0.5rem",
                          sm: "0.75rem",
                          md: ".8rem",
                        },
                        whiteSpace: "normal",
                        margin: "2%",
                      }}
                    >{`${value.style}`}</Typography>
                  </Button>
                );
              })}
            </Stack>
          </ThemeProvider>
        </Box>
      </Popover>
    </div>
  );
}
