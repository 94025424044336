import axios from "axios";
let urlImage;

function getCsrfToken() {
  // Assuming the CSRF token is stored in a cookie named 'csrftoken'
  const csrfToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("csrftoken="))
    ?.split("=")[1];
  return csrfToken;
}

export const convertToGrayscale = async (imageURL) => {
  // Retrieve the token from storage
  const token = localStorage.getItem("accessToken"); // Adjust based on where you store the token
  const csrfToken = getCsrfToken();
  return fetch(imageURL)
    .then((res) => res.blob())
    .then((blob) => {
      const formData = new FormData();
      formData.append("image", blob, "image.jpg");

      return axios.post(
        "https://api.okayartist.com/api/convert_to_grayscale/",
        formData,
        {
          responseType: "blob",
        }
      );
    })
    .then((response) => {
      const url = URL.createObjectURL(response.data);
      return url;
    })
    .catch((error) => {
      console.error("Error uploading image:", error);
      throw error; // Important to propagate the error
    });
};
