import React, { useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { Button, TextField, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function PasswordResetConfirm() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const navigate = useNavigate();
  // const query = useQuery();
  // const uid = query.get("uid");
  // const token = query.get("token");
  const { uid, token } = useParams();

  const handleSubmit = async (e) => {
    if (newPassword === confirmNewPassword) {
      e.preventDefault();
      try {
        const response = await axios.post(
          "https://api.okayartist.com/auth/users/reset_password_confirm/",
          {
            uid,
            token,
            new_password: newPassword,
          }
        );

        console.log("Password reset confirmed successfully:", response.data);
        // Redirect to login page or show success message
      } catch (error) {
        console.error(
          "Error during password reset confirmation:",
          error.response ? error.response.data : error
        );
        // Handle the error
      }
    } else {
      console.log("Passwords doesn't match");
    }
    navigate("/login");
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        label="New Password"
        variant="outlined"
        type="password"
        required
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        id="newPassword"
      />
      <TextField
        label="Confirm New Password"
        variant="outlined"
        type="password"
        required
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
        id="confirmNewPassword"
      />
      <Button type="submit" variant="contained" sx={{ mt: 2 }}>
        Reset Password
      </Button>
    </Box>
  );
}
