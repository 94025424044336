import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#87CEEB", // your custom color
    },
    background: {
      primary: "#f8f5f2", // First background color option
      secondary: "#ffffff", // Second background color option
    },
    // Define other colors here
  },

  text: {
    primary: "red",
    secondary: "#87CEEB",
  },
  typography: {
    // gridMenu: { fontSize: "4rem", color: "red" },
    fontWeightRegular: 400,
    // Define the default font size and style for 'body1'
    body1: {
      // fontSize: 3, // default font size
      "@media (min-width:600px)": {
        fontSize: 10, // font size on screens ≥ 600px
      },
      "@media (min-width:900px)": {
        fontSize: 15, // font size on screens ≥ 900px
      },
    },
    // Add other typography styles as needed
    h1: {
      // Example for h1 headers
      fontSize: 24,
      "@media (min-width:600px)": {
        fontSize: 30,
      },
    },
    // ... add more styles for other elements if needed
  },
  // Add other theme customizations here
});

// Apply responsive font sizes
// theme = responsiveFontSizes(theme);

export default theme;
