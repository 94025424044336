import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setText } from "./redux-slices/TextSlice";
import { fabric } from "fabric";
import { CanvasContext } from "./CanvasContext";
import { useMediaQuery } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {
  setActiveTemplate,
  setImgTemplateURL,
} from "./redux-slices/ImageSlice";
import zIndex from "@mui/material/styles/zIndex";
import FloatingToolbar from "./FloatingToolbar";
import Box from "@mui/material/Box";
import ConvertImageToGrayscale from "./OpencvJS/ConvertImageToGrayscale";
import MaskImages from "./OpencvJS/MaskImages";
import { setImageURL } from "./redux-slices/ImageSlice";

const CanvasArea = () => {
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const {
    canvasRef,
    canvasRefTemplate,
    setCanvasAspectRatio,
    setImgAspectRatio,
    setScaleGlobal,
    imgWidth,
    imgHeight,
    open,
    setOpen,
  } = useContext(CanvasContext);
  // const canvasRef = useRef(null);
  const imageURL = useSelector((state) => state.image.imageURL); // Access the image URL from the Redux store
  const activeTemplate = useSelector((state) => state.image.activeTemplate); // Access the image URL from the Redux store
  const textOption = useSelector((state) => state.textState.text);
  // console.log("BREAKPOINT inside CanvasArea :>:>:>:>:>:>:>:>:>:>:>:>:>");
  const dispatch = useDispatch();
  // const [imgWidth, setImgWidth] = useState(0);
  // const [imgHeight, setImgHeight] = useState(0);

  // let imageWidth, imageHeight;
  // fabric.Image.fromURL(imageURL, (img) => {
  //   // console.log("BREAKPOINT ------- img.width= " + img.width);
  //   // return img.width;
  //   // imageWidth = img.width;
  //   // imageHeight = img.height;
  // });
  // console.log("BREAKPOINT ------- imageWidth= " + imageWidth);
  const [isCanvasInitialized, setIsCanvasInitialized] = React.useState(false);

  useEffect(() => {
    if (imageURL) {
      // Initialize Fabric.js canvas
      const fabricCanvas = new fabric.Canvas(canvasRef.current, {
        width: window.innerWidth * 0.8,
        height: window.innerHeight * 0.8,
        // width: imgWidth,
        // height: imgHeight,
        // backgroundColor: "green",

        preserveObjectStacking: true,
        interactive: true, // Ensure interactivity is enabled
      });
      setIsCanvasInitialized(true);

      // console.log(
      //   "BREAKING POINT --------- inside CanvasArea fabricCanvas.width = " +
      //     fabricCanvas.width
      // );
      // console.log(
      //   "BREAKING POINT --------- inside CanvasArea imgWidth = " + imgWidth
      // );
      // console.log(
      //   "BREAKING POINT --------- inside CanvasArea imgHeight = " + imgHeight
      // );

      canvasRef.current.fabric = fabricCanvas; // Store the Fabric.js canvas instance

      const resizeCanvas = () => {
        fabricCanvas.setDimensions({
          width: window.innerWidth * 0.8,
          height: window.innerHeight * 0.8,
        });
      };

      window.addEventListener("resize", resizeCanvas);
      // Load and add image to the canvas
      if (isCanvasInitialized && fabricCanvas) {
        fabric.Image.fromURL(imageURL, (img) => {
          // console.log("BREAKPOINT ---------- img.width= " + img.width);
          const canvasAspectRatio = fabricCanvas.width / fabricCanvas.height;
          const imgAspectRatio = img.width / img.height;
          let scale;

          if (imgAspectRatio > canvasAspectRatio) {
            // If image aspect ratio is greater than canvas's, fit image by width
            scale = fabricCanvas.width / img.width;
          } else {
            // Else, fit image by height
            scale = fabricCanvas.height / img.height;
          }
          setScaleGlobal(scale);
          // Setting the scale and centering the image on the canvas
          img.set({
            scaleX: scale,
            scaleY: scale,
            left: 0,
            top: 0,
            originX: "left", // Set origin to the top-left corner
            originY: "top", // Set origin to the top-left corner
            myCustomId: "image",
            selectable: true, // Keep the image selectable, or set to false to prevent selection
            hasControls: false, // Hide controls, prevent resizing
            hasBorders: false, // Hide borders
            lockMovementX: true, // Prevent moving on the x-axis
            lockMovementY: true, // Prevent moving on the y-axis
            lockRotation: true, // Prevent rotation
            lockScalingX: true, // Prevent scaling on the x-axis
            lockScalingY: true, // Prevent scaling on the y-axis
            lockSkewingX: true, // Prevent skewing on the x-axis
            lockSkewingY: true, // Prevent skewing on the y-axis
          });
          // console.log(
          //   "BREAKPOINT img left= " +
          //     img.left +
          //     ",top= " +
          //     img.top +
          //     ", width= " +
          //     img.width +
          //     ", height= " +
          //     img.height
          // );
          fabricCanvas.add(img);
          fabricCanvas.sendToBack(img);
          fabricCanvas.setDimensions({
            // scaleX: scale,
            // scaleY: scale,
            width: img.width * scale,
            height: img.height * scale,
            left: 0,
            top: 0,
            originX: "left", // Set origin to the top-left corner
            originY: "top", // Set origin to the top-left corner
            // myCustomId: "image",
          });
          fabricCanvas.renderAll(); // Render the canvas to display the image
        });
      }
      return () => {
        fabricCanvas.dispose();
        dispatch(setImgTemplateURL(""));
        window.removeEventListener("resize", resizeCanvas);
        setCanvasAspectRatio(1);
        setImgAspectRatio(1);
        // dispatch(setImageURL(""));
        // setIsCanvasInitialized(false);
      };
    }
  }, [imageURL, isCanvasInitialized]);

  //addition of text feature
  useEffect(() => {
    if (textOption) {
      const fabricCanvas = canvasRef.current.fabric; // Ensure this references the Fabric.js canvas
      if (!fabricCanvas) return;

      const newText = new fabric.IText("Edit Text", {
        left: 100, // Position from the left on the canvas
        top: 100, // Position from the top on the canvas
        fontFamily: "Arial",
        fill: "white",
        fontSize: 20,
      });

      fabricCanvas.add(newText);
      fabricCanvas.bringToFront(newText);
      fabricCanvas.renderAll(); // Render to display the changes
      dispatch(setText());
    }
  }, [textOption]);

  return (
    <div
      style={{
        display: "flex",
        height: !matchesXS ? "85vh" : "78vh",
        overflow: "auto",
        justifyContent: "center",
        // backgroundColor: "green",
      }}
      // id="canvas"
      // style={{ position: "relative" }}
      // onClick={textOption ? addText : ""}
    >
      {activeTemplate && <FloatingToolbar></FloatingToolbar>}

      {/* <PopoverMenu /> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <canvas
          id="canvas"
          ref={canvasRef}
          style={{
            // maxWidth: "100%",
            // maxHeight: "85vh",
            // border: "1px solid black",
            // backgroundColor: "white",
            display: "block",
            // marginLeft: "auto",
            // marginRight: "auto",
            // zIndex: "0",
          }}
        ></canvas>
      </Box>
      {/* <MaskImages /> */}

      {/* <TypographyCanvas /> */}
    </div>
  );
};
export default CanvasArea;
